import React, { useEffect, useMemo, useState } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as sentry from '@sentry/browser';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import firebase from './utils/firebase';
import './login.css';
import AuthenticatedView, { User } from './AuthenticatedView';
import WarpError from './WarpError';
import LoadingScreen from './loading-screen/LoadingScreen';
import createRedirectForLinking from './utils/redirectToLinking';

export interface ApolloWrapperProps {
  setReferralCode: React.Dispatch<React.SetStateAction<string>>;
  setAnonymousUserLinked: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  // Whether there's an in-flight request to fetch the user from storage.
  userLoading: boolean;
  // Whether there was an error fetching the user from storage.
  userError: boolean;
  logout: () => Promise<void>;
  isUserAnonymous: boolean;
}

export const GET_OR_CREATE_USER = gql`
  mutation GetOrCreateUser($referral_code: String) {
    getOrCreateUser(referral_code: $referral_code) {
      email
      firebase_uid
      is_user_new
      is_onboarded
      anonymous_user_info {
        type
        linkedAt
      }
      joinable_teams {
        teamUid
        numMembers
        name
        teamAcceptingInvites
      }
    }
  }
`;

const IS_TELEMETRY_ENABLED = gql`
  query GetUserSettings {
    user {
      user_settings {
        isTelemetryEnabled
      }
    }
  }
`;

const cache = new InMemoryCache({});
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_ROOT,
});
const apolloClient = new ApolloClient({
  cache,
  link: httpLink,
});

export const AuthenticatedApolloWrapper = ({
  allowAnonymous,
  children,
}: any) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [userError, setUserError] = useState<boolean>(false);
  const [telemetryEnabled, setTelemetryEnabled] = useState<boolean>(false);
  const [anonymousUserLinked, setAnonymousUserLinked] = useState<boolean>(
    false
  );

  const [referralCode, setReferralCode] = useState<string | undefined>(
    undefined
  );

  // setAuthToken is responsible for calling setToken (to ensure any effects are triggered)
  // and additionally updates the Apollo client's links to attach the authorization header
  // to every request with the correct token.
  const setAuthToken = (authToken: string) => {
    setToken(authToken);
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
      },
    }));
    apolloClient.setLink(authLink.concat(httpLink));
  };

  // We're not keying off the `loading` prop from `useMutation`, because it only turns to true
  // when the mutation function is invoked, and there's a period of time at the beginning of the
  // lifecycle of this component where we need to perform some token header setup before we can
  // call the function, and so we'd incorrectly tell children that the user was not loading.
  // We manage it manually via the `userLoading` state variable.
  const [getOrCreateUser, { error }] = useMutation(GET_OR_CREATE_USER, {
    client: apolloClient,
  });

  const { error: telemetryError } = useQuery(IS_TELEMETRY_ENABLED, {
    client: apolloClient,
    skip: !user,
    onCompleted(data) {
      setTelemetryEnabled(data?.user?.user_settings?.isTelemetryEnabled);
    },
  });
  if (telemetryError) {
    setTelemetryEnabled(false);
  }

  useMemo(() => {
    setUserError(!!error);
  }, [error]);

  useEffect(() => {
    // Set up an onAuthStateChanged handler after each render of the auth
    // wrapper, and give React the unsubscribe function returned by
    // `onAuthStateChanged` so that it removes the listener before
    // re-rendering (to avoid incrementing the listener count on every render).
    return getAuth(firebase).onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        // Store the ID Token (JWT) in memory - we purposefully do not store this in
        // local storage as it is recommended to only store auth tokens in memory
        // for an SPA.
        setAuthToken(await firebaseUser.getIdToken());
      } else {
        setUserLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    // Do nothing if the token has not been set yet.  Trying to look up a user
    // without an authentication token is guaranteed to fail (with a 401).
    if (!token) {
      return;
    }

    // Now that we have a (presumably valid) authentication token, we can request
    // user information from our `/graphql` endpoint.
    getOrCreateUser({ variables: { referral_code: referralCode || '' } }).then(
      ({ data }) => {
        const firebaseUser = getAuth(firebase).currentUser;
        // Only set the user if there's a valid firebase user _and_ a user exists in storage.
        if (firebaseUser && data?.getOrCreateUser?.firebase_uid) {
          const {
            displayName,
            email,
            photoURL,
            refreshToken,
            uid,
          } = firebaseUser;

          const warpUser: User = {
            displayName,
            email,
            photoURL,
            refreshToken,
            firebaseUID: uid,
            isUserNew: !!data?.getOrCreateUser?.is_user_new,
            isOnboarded: !!data?.getOrCreateUser?.is_onboarded,
            joinableTeams: data?.getOrCreateUser?.joinable_teams,
            isTelemetryEnabled: telemetryEnabled,
            anonymousUserType: data?.getOrCreateUser?.anonymous_user_info?.type,
            isAnonymous: !!data?.getOrCreateUser?.anonymous_user_info,
          };

          setUser(warpUser);
          setUserLoading(false);

          const userProperties = {
            name: displayName,
            email,
            photoURL,
          };
          window.rudderanalytics.identify(firebaseUser.uid, userProperties);

          sentry.configureScope((scope: sentry.Scope) => {
            scope.setUser({
              id: uid,
              email: email || undefined,
            });
          });
        }
      }
    );
  }, [
    token,
    referralCode,
    getOrCreateUser,
    telemetryEnabled,
    anonymousUserLinked,
  ]);

  const logout = () => {
    const promise = getAuth(firebase)
      .signOut()
      .then((_result) => {
        setUser(undefined);
        setAuthToken('');
      });

    // Remove all parameters from url:
    // 1) Get the base URL without parameters
    const baseUrl = window.location.origin + window.location.pathname;
    // 2) Replace the current URL with the base URL
    window.history.replaceState({}, document.title, baseUrl);

    return promise;
  };

  if (userError) {
    return <WarpError error="Oops! Sign In failed. Please try again later." />;
  }
  if (userLoading) {
    return <LoadingScreen />;
  }
  if (!allowAnonymous && user?.isAnonymous) {
    return (
      <Navigate to={createRedirectForLinking(user.refreshToken)} replace />
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AuthenticatedView user={user!} logout={logout}>
        {children({
          user,
          logout,
          setReferralCode,
          userLoading,
          userError,
          setAnonymousUserLinked,
        })}
      </AuthenticatedView>
    </ApolloProvider>
  );
};

export const NoAuthApolloWrapper = ({ children }: any) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
