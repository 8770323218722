import React from 'react';
import './upgrade.css';
import { PRO_PLAN_AI_REQUEST_LIMIT } from '../const';

const ProPlanDescription = () => {
  return (
    <div className="upgrade-plan-description">
      For professional engineers to leverage advanced AI
      <ul>
        <li>{PRO_PLAN_AI_REQUEST_LIMIT} Warp AI requests per user per month</li>
        <li>Private email support</li>
      </ul>
    </div>
  );
};

export default ProPlanDescription;
