import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { DisplaySetting } from './block';
import WarpError from './WarpError';
import Warp404 from './404';
import WarpAnsiBlock from './WarpAnsiBlock';

const GET_BLOCK_QUERY = gql`
  query GetBlock($id: String!) {
    getBlock(id: $id) {
      title
      stylizedPrompt
      stylizedCommand
      stylizedPromptAndCommand
      stylizedOutput
      command
      output
      showPrompt
      embedDisplaySetting
    }
  }
`;

function EmbedView() {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery(GET_BLOCK_QUERY, {
    variables: { id },
  });

  React.useEffect(() => {
    // Only emit event on successful/completed render.
    if (!error && !loading) {
      window.rudderanalytics.track('Viewed Embed View', { id });
    }
  }, [error, id, loading]);

  if (error) {
    if (
      error.message &&
      error.message === 'Not found: sql: no rows in result set'
    ) {
      return <Warp404 />;
    }
    return <WarpError error={error?.message} />;
  }
  if (loading) {
    return <WarpError error="Loading..." />;
  }

  const showCommand =
    data.getBlock.embedDisplaySetting === DisplaySetting.COMMAND ||
    data.getBlock.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  const showOutput =
    data.getBlock.embedDisplaySetting === DisplaySetting.OUTPUT ||
    data.getBlock.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  return (
    <div className="App-embed">
      <div className="embed-view-wrapper">
        <WarpAnsiBlock
          blockRenderInfo={{
            block: data.getBlock,
            showCommand,
            showOutput,
            isEmbed: true,
          }}
          isEmbed
        />
      </div>
    </div>
  );
}

export default EmbedView;
