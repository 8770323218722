import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import { getDefaultScheme } from '../utils/env';

const openIntent = () => {
  window.location.replace(`${getDefaultScheme()}://team/desktop_redirect`);
};

const OpenWarpButton = ({ text }: { text?: string }) => {
  return (
    <ModalContainerButton
      treatment={ModalContainerButtonTreatment.FullWidth}
      buttonType={ModalContainerButtonType.Button}
      accent={ModalContainerButtonAccent.Primary}
      content={text ? <>{text}</> : <>Open Warp</>}
      onClickFunction={() => openIntent()}
    />
  );
};

OpenWarpButton.defaultProps = {
  text: undefined,
};

export default OpenWarpButton;
