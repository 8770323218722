import React from 'react';
import './upgrade.css';
import { TEAM_PLAN_AI_REQUEST_LIMIT } from '../const';

const TeamPlanDescription = () => {
  return (
    <div className="upgrade-plan-description">
      For engineering teams who want to collaborate and streamline ops
      <ul>
        <li>
          {TEAM_PLAN_AI_REQUEST_LIMIT} Warp AI requests per user per month
        </li>
        <li>Shared Notebooks and Workflows in Warp Drive</li>
        <li>Real-time session sharing (Beta)</li>
        <li>Private email support</li>
      </ul>
    </div>
  );
};

export default TeamPlanDescription;
