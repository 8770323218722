import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import firebase from '../utils/firebase';
import './email-sent-view.css';
import { User } from '../AuthenticatedView';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';

import AuthView from './AuthView';

interface LinkAnonymousUserViewProps {
  user: User | undefined;
  setAnonymousUserLinked: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => Promise<void>;
}

const LinkAnonymousUserView = ({
  user,
  setAnonymousUserLinked,
  logout,
}: LinkAnonymousUserViewProps) => {
  const auth = getAuth(firebase);
  const { currentUser } = auth;
  const { customToken } = useParams<{
    customToken: string;
  }>();
  const navigate = useNavigate();

  const loadingState = (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>Loading...</ModalContainerHeader>
    </ModalContainer>
  );

  // Run a one-time function to potentially sign the anonymous user into Firebase.
  // Note that this is distinct from getting the user from Warp.
  useEffect(() => {
    const checkAnonymousUserSignedIn = async () => {
      await signInWithCustomToken(auth, customToken || '');
    };

    if (!currentUser) {
      checkAnonymousUserSignedIn();
    }
  }, [auth, customToken, currentUser]);

  useEffect(() => {
    if (currentUser && !!user?.isAnonymous) {
      // Strip the custom token from the url at this point to prevent it from being leaked.
      navigate('/link_anonymous_user', { replace: true });
    }
  }, [currentUser, navigate, user]);

  if (currentUser) {
    return (
      <AuthView
        headerContent={<>Please sign in to continue using Warp</>}
        showLoadingState={false}
        buttonTrackingObject={{
          segmentMessage:
            'Click Sign In Button on Login View to Link Anonymous User',
          googleAnalyticsMessage: 'Link User on Login View',
          googleAnalyticsCategory: 'Login',
        }}
        user={user}
        setAnonymousUserLinked={setAnonymousUserLinked}
        logout={logout}
      />
    );
  }

  return loadingState;
};

export default LinkAnonymousUserView;
