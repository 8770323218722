import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams, Redirect } from 'react-router-dom';
import { User } from './ApolloWrapper';
import './download.css';
import './login.css';
import AuthView from './auth/AuthView';

const GET_REFERRER = gql`
  query Referrer($referral_code: String!) {
    referrer(referral_code: $referral_code)
  }
`;

interface ReferredSignupProps {
  user: User | undefined;
  setReferralCode: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * `ReferredSignupView` allows users to claim their referral and sign up for Warp.
 * On success, they will be redirected to a logged in page with a download button.
 * Should any error occur, the user will be redirected to another signup page
 * where they can sign up without a referral.
 */
const ReferredSignupView = ({ user, setReferralCode }: ReferredSignupProps) => {
  const { referral_code: referralCode } = useParams<{
    referral_code: string;
  }>();
  const {
    loading: referrerLoading,
    error: referrerError,
    data: referrerData,
  } = useQuery(GET_REFERRER, {
    variables: { referral_code: referralCode },
  });

  useEffect(() => {
    // Only set the referral code if the referral is from a valid referer.
    if (referrerData?.referrer) {
      setReferralCode(referralCode);
    }
  }, [referrerData, setReferralCode, referralCode]);

  const { referrer } = referrerData || {};
  let headerContent;
  if (referrer && !referrerError) {
    if (user?.isTelemetryEnabled) {
      window.rudderanalytics.track('Visited Referred Signup of Referrer');
    }
    headerContent = <>{`${referrer} has invited you to try Warp.`}</>;
  } else {
    if (user?.isTelemetryEnabled) {
      window.rudderanalytics.track(
        'Visited Referred Signup with Invalid Code',
        {
          referralCode,
        }
      );
    }
    headerContent = (
      <>
        Oops! There was a problem with this referral code. You can sign up
        without a code or try again later.
      </>
    );
  }

  // If the user is already logged in, send them to the download link page
  if (user) {
    return <Redirect to="/logged_in/download?referral=true" />;
  }

  return (
    <AuthView
      headerContent={headerContent}
      showLoadingState={referrerLoading}
      buttonTrackingObject={{
        segmentMessage: 'Click Sign Up Button on Referred Signup View',
        googleAnalyticsMessage: 'Sign Up on Referred Sign Up View',
        googleAnalyticsCategory: 'Sign Up',
      }}
      user={user}
    />
  );
};

export default ReferredSignupView;
