import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GET_OBJECT_QUERY,
  GET_OBJECT_STAGING_QUERY,
} from './queries/CloudObject';

import { User } from './AuthenticatedView';
import createRedirectAfterLoginPath from './utils/redirectAfterLogin';
import LoadingScreen from './loading-screen/LoadingScreen';
import WasmView from './WasmView';
import FullPageModal from './FullPageModal';
import { FEATURE_FLAGS } from './utils/env';
import RequestAccessModal from './RequestAccessModal';

enum AllowedObjectTypes {
  Notebook = 'notebook',
  Workflow = 'workflow',
  EnvironmentVariables = 'env-vars',
}

interface DriveViewProps {
  user: User;
}

function getAppLaunchURL(
  objectType: AllowedObjectTypes,
  objectUID: string
): string {
  return `${process.env.REACT_APP_DEFAULT_SCHEME}://drive/${objectType}?id=${objectUID}`;
}

function getObjectName(data: { [key: string]: any }): string | null {
  const {
    __typename: typename,
    titleName,
    data: jsonData,
    format,
  } = data.getCloudObject.object;

  if (typename === 'NewNotebook') {
    return titleName;
  }

  if (typename === 'Workflow') {
    return JSON.parse(jsonData).name;
  }

  if (typename === 'GenericStringObject') {
    if (format === 'JsonEnvVarCollection') {
      return 'environment variables';
    }
  }
  return null;
}

function mapObjectTypeToEnum(objectType: string): AllowedObjectTypes | null {
  switch (objectType) {
    case AllowedObjectTypes.Notebook:
      return AllowedObjectTypes.Notebook;
    case AllowedObjectTypes.Workflow:
      return AllowedObjectTypes.Workflow;
    case AllowedObjectTypes.EnvironmentVariables:
      return AllowedObjectTypes.EnvironmentVariables;
    default:
      return null;
  }
}

function getObjectUID(objectSlug: string): string {
  const parts = objectSlug.split('-');
  return parts[parts.length - 1];
}

function validateResourceType(
  data: { [key: string]: any },
  objectType: AllowedObjectTypes
): boolean {
  // eslint-disable-next-line no-underscore-dangle
  const actualResourceType = data?.getCloudObject?.object?.__typename;
  if (
    actualResourceType === 'NewNotebook' &&
    objectType === AllowedObjectTypes.Notebook
  ) {
    return true;
  }

  if (
    actualResourceType === 'Workflow' &&
    objectType === AllowedObjectTypes.Workflow
  ) {
    return true;
  }

  if (
    actualResourceType === 'GenericStringObject' &&
    objectType === AllowedObjectTypes.EnvironmentVariables
  ) {
    return true;
  }

  return false;
}

const NOT_FOUND_MESSAGE = "The resource you're looking for could not be found.";

const DriveView = ({ user }: DriveViewProps) => {
  // TODO figure out the correct analytics
  window.rudderanalytics.track('Visited drive link view');
  const { pathname } = useLocation();
  const params = useParams<{ object_type: string; object_slug: string }>();

  const objectType = mapObjectTypeToEnum(params.object_type || '');
  const objectUID = getObjectUID(params.object_slug || '');

  const { loading, error: objectError, data } = useQuery(
    FEATURE_FLAGS.REACT_APP_ENABLE_ENV_VARS
      ? GET_OBJECT_STAGING_QUERY
      : GET_OBJECT_QUERY,
    {
      variables: { uid: objectUID },
    }
  );

  if (!user) {
    return <Navigate to={createRedirectAfterLoginPath(pathname)} replace />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (objectError?.message) {
    if (objectError?.message.startsWith('Unauthorized')) {
      return <RequestAccessModal objectUID={objectUID} />;
    }
    return <FullPageModal error={NOT_FOUND_MESSAGE} />;
  }

  if (
    !objectType ||
    !objectUID ||
    data?.getCloudObject?.object?.metadata?.trashedTs ||
    !validateResourceType(data, objectType)
  ) {
    return <FullPageModal error={NOT_FOUND_MESSAGE} />;
  }

  const splashPageMessage = `Open Warp to view ${
    getObjectName(data) ??
    `this ${objectType[0].toUpperCase() + objectType.slice(1)}`
  }`;

  if (objectType === AllowedObjectTypes.EnvironmentVariables) {
    return (
      <FullPageModal
        appLaunchUrl={getAppLaunchURL(objectType, objectUID)}
        message={splashPageMessage}
      />
    );
  }

  // Otherwise just return the wasm component
  return (
    <WasmView
      splashPageMessage={splashPageMessage}
      appLaunchUrl={getAppLaunchURL(objectType, objectUID)}
    />
  );
};

export default DriveView;
