import { gql } from '@apollo/client';

// Get object
export const GET_OBJECT_QUERY = gql`
  query GetObject($uid: ID!) {
    getCloudObject(input: { objectId: $uid }) {
      object {
        ... on NewNotebook {
          titleName
          metadata {
            trashedTs
          }
        }
        ... on Workflow {
          data
          metadata {
            trashedTs
          }
        }
      }
    }
  }
`;

export const GET_OBJECT_STAGING_QUERY = gql`
  query GetObjectStaging($uid: ID!) {
    getCloudObject(input: { objectId: $uid }) {
      object {
        ... on NewNotebook {
          titleName
          metadata {
            trashedTs
          }
        }
        ... on Workflow {
          data
          metadata {
            trashedTs
          }
        }
        ... on GenericStringObject {
          format
          metadata {
            trashedTs
          }
        }
      }
    }
  }
`;

export const CHECK_AND_RECORD_OBJECT_ACCESS_MUTATION = gql`
  mutation CheckAndRecordObjectAccess($uid: ID!) {
    checkAndRecordObjectAccess(input: { objectUID: $uid }) {
      ... on CheckAndRecordObjectAccessOutput {
        objectName
        isTrashed
      }
    }
  }
`;
