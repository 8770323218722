import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import DownloadWarpBlock from './DownloadWarpBlock';
import OpenWarpButton from './OpenWarpButton';

export interface SuccessfulJoinProps {
  teamNameFromInviteCode: string | undefined;
}

const SuccessfulJoin = ({ teamNameFromInviteCode }: SuccessfulJoinProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Check}>
      <ModalContainerHeader>
        You&apos;ve joined{' '}
        <span className="font-weight-600">{teamNameFromInviteCode}</span>,
        welcome.
      </ModalContainerHeader>
      <ModalContainerBody>
        <DownloadWarpBlock />
        <OpenWarpButton />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default SuccessfulJoin;
