import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';

const DownloadWarpBlock = () => {
  return (
    <>
      <div>
        Still need Warp?{' '}
        <span>
          <ModalContainerButton
            treatment={ModalContainerButtonTreatment.Inline}
            href="/get_warp?auto_download=false"
            content={<>Download it here.</>}
          />
        </span>
      </div>
      <div>
        Don&apos;t see your team in-app? Try{' '}
        <span>
          <ModalContainerButton
            treatment={ModalContainerButtonTreatment.Inline}
            href="https://docs.warp.dev/help/updating-warp"
            openInNewTab
            content={<>updating Warp</>}
          />
        </span>
        .
      </div>
    </>
  );
};

export default DownloadWarpBlock;
