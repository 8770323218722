import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import WasmComponent from './WasmComponent';
import { FEATURE_FLAGS } from './utils/env';
import FullPageModal from './FullPageModal';
import AuthHeaderContext from './auth/AuthHeaderContext';
import LoadingScreen from './loading-screen/LoadingScreen';
import { OpenOnNativeEvent, WarpEventKind, warpEventBus } from './warp-client';
import { checkWoWStatus } from './warp-client/requirements';
import UnsupportedModal from './warp-client/UnsupportedModal';

// NOTE: This string much rename in sync with the one that defines the setting in warp-internal
// https://github.com/warpdotdev/warp-internal/blob/86f45a40e9b0dd29fe98f40a2c02d33805d8be22/app/src/settings/native_preference.rs#L20-L21
const USER_NATIVE_PREFERENCE_KEY = 'UserNativePreference';

enum UserNativePreference {
  NotSelected = 'NotSelected',
  Web = 'Web',
  Desktop = 'Desktop',
}

// When checking for whether or not the app is installed, we call into a small local http server
// that's run as sub-process from the main app.
// For more details, see: https://docs.google.com/document/d/1gtXisOIS7OYahlL-cxl6woPyOo5uVazswQKLeL6jyzI/edit#heading=h.jpwfi2pfd9he
const USER_APP_INSTALLATION_DETECTION_URL =
  'http://localhost:9277/install_detection';

// Similar to the native preference settings, this must map to the setting in warp-internal
// https://github.com/warpdotdev/warp-internal/blob/master/app/src/settings/app_installation_detection.rs#L18
const USER_APP_INSTALLATION_DETECTION_KEY = 'UserAppInstallStatus';

enum UserAppInstallationStatus {
  NotDetected = 'NotDetected',
  Detected = 'Detected',
}

interface WasmViewProps {
  splashPageMessage: string;
  appLaunchUrl: string;
}

const WasmView = ({ splashPageMessage, appLaunchUrl }: WasmViewProps) => {
  const setDisplayAuthViewHeader = useContext(AuthHeaderContext);
  const [shouldDirectToDesktop, setShouldDirectToDesktop] = useState(true);

  const [nativePreferenceIsLoading, setNativePreferenceIsLoading] = useState(
    true
  );
  const [
    appInstallationDetectLoading,
    setAppInstallationDetectLoading,
  ] = useState(true);

  useEffect(() => {
    // Try to read the user app installation status from local storage
    let userAppInstallationStatus = UserAppInstallationStatus.NotDetected;
    const userAppInstallationStatusSetting = window.localStorage.getItem(
      USER_APP_INSTALLATION_DETECTION_KEY
    );

    if (userAppInstallationStatusSetting !== null) {
      // Note: we have to use two JSON.parse here because of the way the warp app's API
      // writes and reads to local storage. It would be cleaner to store the value of the status
      // as just a string, but then it would not be compatible with having warp app read and
      // write to the same value.
      const installDetectionString: String = JSON.parse(
        JSON.parse(userAppInstallationStatusSetting)
      );
      const userAppInstallationStatusFromStorage =
        UserAppInstallationStatus[
          installDetectionString as keyof typeof UserAppInstallationStatus
        ];

      if (userAppInstallationStatusFromStorage !== undefined) {
        userAppInstallationStatus = userAppInstallationStatusFromStorage;
      }
    }

    // If we haven't successfully seen a warp desktop app, try to ping it
    if (userAppInstallationStatus === UserAppInstallationStatus.NotDetected) {
      fetch(USER_APP_INSTALLATION_DETECTION_URL, {
        method: 'GET',
      })
        .then((response) => {
          if (response.status === 200) {
            window.localStorage.setItem(
              USER_APP_INSTALLATION_DETECTION_KEY,
              JSON.stringify(`"${UserAppInstallationStatus.Detected}"`)
            );
          }
          setAppInstallationDetectLoading(false);
        })
        .catch((_) => {
          window.localStorage.setItem(
            USER_APP_INSTALLATION_DETECTION_KEY,
            JSON.stringify(`"${UserAppInstallationStatus.NotDetected}"`)
          );
          setAppInstallationDetectLoading(false);
        });
    } else {
      setAppInstallationDetectLoading(false);
    }
  }, []);

  useEffect(() => {
    let userNativePreference = UserNativePreference.NotSelected;
    const userNativePreferenceSetting = window.localStorage.getItem(
      USER_NATIVE_PREFERENCE_KEY
    );

    if (userNativePreferenceSetting !== null) {
      // Same logic as above for the double parse.
      const preferenceString: String = JSON.parse(
        JSON.parse(userNativePreferenceSetting)
      );
      const userNativePreferenceFromStorage =
        UserNativePreference[
          preferenceString as keyof typeof UserNativePreference
        ];

      if (userNativePreferenceFromStorage !== undefined) {
        userNativePreference = userNativePreferenceFromStorage;
      }
    }

    // If the user doesn't have the setting explicitly to desktop - then route to the web.
    // If they do have it set to desktop, this should override the app installation detection logic
    // above as the app installation flow is not 100% correct in determining the install status and
    // honoring the user's choice of redirect is more important.
    if (userNativePreference !== UserNativePreference.Desktop) {
      setShouldDirectToDesktop(false);
      setDisplayAuthViewHeader(false);
    }
    setNativePreferenceIsLoading(false);
  }, [setDisplayAuthViewHeader]);

  useEffect(() => {
    warpEventBus.addListener((event) => {
      if (event.kind === WarpEventKind.OpenOnNative) {
        window.open((event as OpenOnNativeEvent).url, '_self');
      }
    });
  }, []);

  if (nativePreferenceIsLoading || appInstallationDetectLoading) {
    return <LoadingScreen />;
  }

  if (isMobile) {
    return (
      <FullPageModal error="Please visit this link on a desktop or laptop computer with Warp installed." />
    );
  }

  // If Warp on Web will not be able to load, redirect to desktop.
  const wowStatus = checkWoWStatus();
  if (!wowStatus.supported) {
    return (
      <UnsupportedModal
        status={wowStatus}
        redirectMessage={splashPageMessage}
        appLaunchUrl={appLaunchUrl}
      />
    );
  }

  // If we want to direct to desktop, or the feature flag for wasm warp is not enabled
  if (shouldDirectToDesktop || !FEATURE_FLAGS.REACT_APP_ENABLE_EMBEDDED_WASM) {
    const viewOnWebCallback = () => {
      setShouldDirectToDesktop(false);
      setDisplayAuthViewHeader(false);
    };

    return (
      <FullPageModal
        appLaunchUrl={appLaunchUrl}
        message={splashPageMessage}
        viewOnWebCallbak={viewOnWebCallback}
      />
    );
  }

  // Otherwise just return the wasm component
  return (
    <>
      <WasmComponent />
    </>
  );
};

export default WasmView;
