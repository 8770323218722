import React from 'react';

import { SharedBlockHeaderProps } from './block';
import copyText from './utils/copy-block';
import { ReactComponent as CopyIcon } from './assets/icon_copy.svg';
import { ReactComponent as LinkIcon } from './assets/icon_link.svg';

const BlockHeader = ({ block, isEmbed }: SharedBlockHeaderProps) => {
  // If we have an embedded block, then the "Copy link" button needs to copy the link
  // to the normal share view, not the link to the embed view.
  const shareViewLink = isEmbed
    ? window.location.href.replace('/embed', '')
    : window.location.href;
  return (
    <div className="embed-name-header">
      <div className="auto-flex">{block.title}</div>
      <div className="button-group">
        <button type="button" onClick={() => copyText(shareViewLink)}>
          <LinkIcon className="icon" />
          <span>Copy link</span>
        </button>
        <button
          type="button"
          onClick={() => copyText(`${block.command}\n${block.output}`)}
        >
          <CopyIcon className="icon" />
          <span>Copy block</span>
        </button>
      </div>
    </div>
  );
};

export default BlockHeader;
