import React, { useEffect, useState } from 'react';
import { User } from './ApolloWrapper';
import warpLogoWithText from './assets/warp_logo_with_text.svg';
import AuthHeaderContext from './auth/AuthHeaderContext';
import { WarpEventKind, warpEventBus } from './warp-client';

export interface AuthenticatedViewProps {
  user: User;
  children: any;
  logout: () => Promise<void>;
}

// View for common elements for authenticated pages.
// So far, just a header with Warp logo and user profile dropdown.
const AuthenticatedView = ({
  user,
  logout,
  children,
}: AuthenticatedViewProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [shouldDisplayAuthViewHeader, setDisplayAuthViewHeader] = useState(
    true
  );

  // The logout button isn't shown when rendering the WoW WASM app. Instead, listen for in-app logout events,
  // and act as if the button were pressed.
  useEffect(() => {
    return warpEventBus.addListener((event) => {
      if (event.kind === WarpEventKind.LoggedOut) {
        // Refresh the page after logging out, since WoW currently can't log back in.
        logout().then((_) => window.location.reload());
      }
    });
  }, [logout]);

  function circleWithPicture(url: string) {
    return (
      <img
        className="authed-user-image"
        alt="Profile"
        src={url}
        style={{ height: 50 }}
      />
    );
  }

  // Used when the user doesn't have a profile pic associated
  // with their account.
  function circleWithLetter() {
    const displayName = user?.displayName ?? user?.email;
    const firstLetter = displayName?.charAt(0)?.toUpperCase();
    return <div className="profile-circle">{firstLetter}</div>;
  }

  function profileCircle() {
    return (
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          paddingBottom: 5,
        }}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {user?.photoURL ? circleWithPicture(user.photoURL) : circleWithLetter()}
      </button>
    );
  }

  function userDropdown() {
    return (
      <div>
        {profileCircle()}
        {dropdownOpen && (
          <div className="user-profile-expanded">
            {user?.displayName && (
              <div className="user-profile-expanded-item">
                <b>{user.displayName}</b>
              </div>
            )}
            {user?.email && (
              <div className="user-profile-expanded-item">{user.email}</div>
            )}
            <div
              className="user-profile-expanded-item"
              style={{ paddingTop: 20 }}
            >
              <button
                type="button"
                onClick={logout}
                style={{ width: 80, height: 30 }}
                className="action-button"
              >
                Log out
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {shouldDisplayAuthViewHeader && (
        <div className="authenticated-header">
          <a href="https://www.warp.dev">
            <img
              src={warpLogoWithText}
              className="top-left-logo"
              alt="Warp header logo"
            />
          </a>
          {user && userDropdown()}
        </div>
      )}
      <AuthHeaderContext.Provider value={setDisplayAuthViewHeader}>
        {children}
      </AuthHeaderContext.Provider>
    </div>
  );
};

export default AuthenticatedView;
