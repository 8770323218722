export const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
export const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
export const FIREBASE_PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID;
export const FIREBASE_STORAGEBUCKET =
  process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
export const FIREBASE_MESSAGINGSENDERID =
  process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
export const FIREBASE_APPID = process.env.REACT_APP_FIREBASE_APPID;
export const WARP_DESCRIPTION =
  'WARP IS A BLAZINGLY FAST, RUST-BASED TERMINAL REIMAGINED FROM THE GROUND UP TO WORK LIKE A MODERN APP.';
export const WARP_TEAMS_DESCRIPTION =
  'Warp is a modern, Rust-based terminal with AI built in so you and your team can build great software faster.';

export const TEAM_PLAN_MONTHLY_COST_PER_SEAT_PER_MONTH = 25;
export const TEAM_PLAN_YEARLY_COST_PER_SEAT_PER_MONTH = 22;
export const TEAM_PLAN_YEARLY_DISCOUNT_PERCENTAGE = 12;
export const PRO_PLAN_MONTHLY_COST_PER_SEAT_PER_MONTH = 18;
export const PRO_PLAN_YEARLY_COST_PER_SEAT_PER_MONTH = 15;
export const PRO_PLAN_YEARLY_DISCOUNT_PERCENTAGE = 17;
export const PRO_PLAN_AI_REQUEST_LIMIT = 500;
export const TEAM_PLAN_AI_REQUEST_LIMIT = 750;
