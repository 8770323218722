import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from './modal-container/ModalContainer';
import ModalContainerBody from './modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from './modal-container/ModalContainerButton';
import ModalContainerHeader from './modal-container/ModalContainerHeader';
import DesktopRedirect from './utils/DesktopRedirect';
import { FEATURE_FLAGS } from './utils/env';

interface Message {
  message: string;
  error?: never;
  appLaunchUrl: string;
  viewOnWebCallbak?: () => void;
}

interface Error {
  message?: never;
  error: string;
  appLaunchUrl?: never;
  viewOnWebCallbak?: never;
}

type RenderParams = Message | Error;

function FullPageModal({
  message,
  error,
  appLaunchUrl,
  viewOnWebCallbak,
}: RenderParams): JSX.Element {
  return (
    <>
      {appLaunchUrl && <DesktopRedirect url={appLaunchUrl} />}
      <ModalContainer
        iconType={
          error ? ModalContainerIconType.Warning : ModalContainerIconType.Logo
        }
      >
        <ModalContainerHeader>{message ?? error}</ModalContainerHeader>
        <ModalContainerBody>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            {!error && (
              <>
                <ModalContainerButton
                  content={<>Open Warp</>}
                  treatment={ModalContainerButtonTreatment.FullWidth}
                  accent={ModalContainerButtonAccent.Primary}
                  href={appLaunchUrl}
                />
                {FEATURE_FLAGS.REACT_APP_ENABLE_EMBEDDED_WASM &&
                  viewOnWebCallbak !== undefined && (
                    <ModalContainerButton
                      content={<>View on the web</>}
                      treatment={ModalContainerButtonTreatment.FullWidth}
                      accent={ModalContainerButtonAccent.Transparent}
                      onClickFunction={() => {
                        viewOnWebCallbak();
                      }}
                    />
                  )}
              </>
            )}
          </div>
        </ModalContainerBody>
      </ModalContainer>
    </>
  );
}

export default FullPageModal;
