import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { FEATURE_FLAGS, isProd } from './utils/env';
import {
  ApolloWrapperProps,
  AuthenticatedApolloWrapper,
  NoAuthApolloWrapper,
} from './ApolloWrapper';
import DownloadPage from './DownloadPage';
import CanaryPage from './CanaryPage';
import LoggedInView from './authed/LoggedInView';
import { JoinTeamView } from './JoinTeamView';
import { JoinTeamWithInviteEmailView } from './JoinTeamWithInviteEmailView';
import { UserDataView } from './UserDataView';
import DriveView from './DriveView';
import DevSession from './DevSession';
import LoggedInWithDownloadView from './authed/LoggedInWithDownloadView';
import './index.css';
import './dark-theme.css';
import './block.css';
import './xterm_colors.css';
import './dropdown.css';
import WarpError from './WarpError';
import Warp404 from './404';
import AuthenticatedView from './AuthenticatedView';
import { warpCanaryPageEnabled } from './utils/features';
import EmbedView from './EmbedView';
import ShareView from './ShareView';
import useSearchQuery from './utils/useSearchQuery';
import AuthView from './auth/AuthView';
import ReferredSignupView from './ReferredSignupView';
import LinkSsoView from './auth/LinkSsoView';
import UpgradeView from './upgrade-view/UpgradeView';
import UpgradeConfirmationView from './upgrade-view/UpgradeConfirmationView';
import SessionShareView from './SessionShareView';
import LoadingScreen from './loading-screen/LoadingScreen';
import SettingsView from './SettingsView';
import UpgradeRequestSentView from './upgrade-view/UpgradeRequestSentView';
import LinkAnonymousUserView from './auth/LinkAnonymousUser';

const RANDOM_ID_PATH_EXPRESSION = '[0-9a-zA-Z]{22}';
const FIREBASE_ID_PATH_EXPRESSION = '[0-9a-zA-Z]*';

const App = () => {
  // Record every page load in Google Analytics.
  // We can only use `useHistory` in `App` rather than `index.tsx` because
  // it can only be used within a `BrowserRouter`.
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        ReactGA.send({
          hitType: 'pageview',
          page: location.pathname,
        });
      }
    });
    return unlisten;
  }, [listen]);

  const authPaths = [
    '/login',
    '/logged_in',
    '/signup',
    '/link_sso',
    '/referral',
    `/team/:id(${RANDOM_ID_PATH_EXPRESSION})`,
    `/team_invite/:id(${RANDOM_ID_PATH_EXPRESSION})`,
    '/data_management',
    '/confirmation',
    '/upgrade_request_sent',
    `/upgrade/:id(${RANDOM_ID_PATH_EXPRESSION})`,
    `/upgrade/user/:id(${FIREBASE_ID_PATH_EXPRESSION})`,
    '/upgrade',
  ];

  if (FEATURE_FLAGS.REACT_APP_ENABLE_DRIVE_LINKABILITY) {
    authPaths.push('/drive/:object_type/:object_slug');
  }

  if (FEATURE_FLAGS.REACT_APP_ENABLE_REQUEST_ACCESS_FLOW) {
    authPaths.push('/settings/:sub_section');
  }

  if (FEATURE_FLAGS.REACT_APP_ENABLE_SESSION_WEB_LINKS) {
    authPaths.push('/session/:id');
  }

  if (FEATURE_FLAGS.REACT_APP_ENABLE_LINK_ANONYMOUS_USERS) {
    authPaths.push('/link_anonymous_user/:custom_token?');
  }

  if (!isProd()) {
    authPaths.push('/dev_session');
  }

  const searchQuery = useSearchQuery();
  const shouldAutoDownload = searchQuery.has('auto_download')
    ? searchQuery.get('auto_download') === 'true'
    : true;

  return (
    <Switch>
      <Route path={authPaths}>
        <AuthenticatedApolloWrapper>
          {({
            user,
            userLoading,
            userError,
            logout,
            setReferralCode,
            setAnonymousUserLinked,
          }: ApolloWrapperProps) => {
            if (userError) {
              return (
                <WarpError error="Oops! Sign In failed. Please try again later." />
              );
            }
            if (userLoading) {
              return <LoadingScreen />;
            }

            return (
              <>
                <AuthenticatedView user={user} logout={logout}>
                  <Switch>
                    <Route path="/signup">
                      <AuthView
                        headerContent={<>Sign up for Warp</>}
                        showLoadingState={false}
                        buttonTrackingObject={{
                          segmentMessage:
                            'Click Sign Up Button on Sign Up View',
                          googleAnalyticsMessage: 'Sign Up on Sign Up View',
                          googleAnalyticsCategory: 'Sign Up',
                        }}
                        user={user}
                      />
                    </Route>
                    <Route path="/login">
                      <AuthView
                        headerContent={<>Sign in to Warp</>}
                        showLoadingState={false}
                        buttonTrackingObject={{
                          segmentMessage: 'Click Sign In Button on Login View',
                          googleAnalyticsMessage: 'Login on Login View',
                          googleAnalyticsCategory: 'Login',
                        }}
                        user={user}
                      />
                    </Route>
                    <Route path="/link_sso">
                      <LinkSsoView user={user} />
                    </Route>
                    <Route path="/link_anonymous_user/:customToken?">
                      <LinkAnonymousUserView
                        user={user}
                        setAnonymousUserLinked={setAnonymousUserLinked}
                      />
                    </Route>
                    <Route exact path="/referral/:referral_code">
                      <ReferredSignupView
                        user={user}
                        setReferralCode={setReferralCode}
                      />
                    </Route>
                    <Route exact path="/logged_in/download">
                      <LoggedInWithDownloadView user={user} logout={logout} />
                    </Route>
                    <Route path="/logged_in">
                      <LoggedInView user={user} logout={logout} />
                    </Route>
                    <Route
                      path={`/team_invite/:emailAuthCode(${RANDOM_ID_PATH_EXPRESSION})`}
                    >
                      <JoinTeamWithInviteEmailView
                        user={user}
                        logout={logout}
                      />
                    </Route>
                    <Route
                      path={`/team/:inviteCode(${RANDOM_ID_PATH_EXPRESSION})`}
                    >
                      <JoinTeamView user={user} />
                    </Route>
                    <Route path="/data_management">
                      <UserDataView user={user} />
                    </Route>
                    {FEATURE_FLAGS.REACT_APP_ENABLE_DRIVE_LINKABILITY && (
                      <Route path="/drive/:object_type/:object_slug">
                        <DriveView user={user} />
                      </Route>
                    )}
                    <Route path="/upgrade_request_sent">
                      <UpgradeRequestSentView />
                    </Route>
                    <Route
                      path={`/upgrade/:teamUid(${RANDOM_ID_PATH_EXPRESSION})`}
                    >
                      <UpgradeView user={user} logout={logout} />
                    </Route>
                    <Route
                      path={`/upgrade/user/:firebaseUid(${FIREBASE_ID_PATH_EXPRESSION})`}
                    >
                      <UpgradeView user={user} logout={logout} />
                    </Route>
                    <Route path="/upgrade">
                      <UpgradeView user={user} logout={logout} />
                    </Route>
                    <Route path="/confirmation">
                      <UpgradeConfirmationView user={user} />
                    </Route>
                    {FEATURE_FLAGS.REACT_APP_ENABLE_SESSION_WEB_LINKS && (
                      <Route path="/session/:id">
                        <SessionShareView user={user} />
                      </Route>
                    )}
                    {FEATURE_FLAGS.REACT_APP_ENABLE_REQUEST_ACCESS_FLOW && (
                      <Route path="/settings/:sub_section">
                        <SettingsView user={user} />
                      </Route>
                    )}
                    {FEATURE_FLAGS.REACT_APP_ENABLE_EMBEDDED_WASM && (
                      <Route path="/dev_session">
                        <DevSession user={user} />
                      </Route>
                    )}
                  </Switch>
                </AuthenticatedView>
              </>
            );
          }}
        </AuthenticatedApolloWrapper>
      </Route>
      <Route path="/download/r/:invite_code">
        <NoAuthApolloWrapper>
          {() => <DownloadPage canAutoStartDownload={false} />}
        </NoAuthApolloWrapper>
      </Route>
      <Route path="/get_warp">
        <NoAuthApolloWrapper>
          {() => <DownloadPage canAutoStartDownload={shouldAutoDownload} />}
        </NoAuthApolloWrapper>
      </Route>
      {warpCanaryPageEnabled() && (
        <Route path="/canary">
          <NoAuthApolloWrapper>{() => <CanaryPage />}</NoAuthApolloWrapper>
        </Route>
      )}
      <Route path={`/block/embed/:id(${RANDOM_ID_PATH_EXPRESSION})`}>
        <NoAuthApolloWrapper>{() => <EmbedView />}</NoAuthApolloWrapper>
      </Route>
      <Route path={`/block/:id(${RANDOM_ID_PATH_EXPRESSION})`}>
        <NoAuthApolloWrapper>{() => <ShareView />}</NoAuthApolloWrapper>
      </Route>
      <Route path={`/:id(${RANDOM_ID_PATH_EXPRESSION})`}>
        <NoAuthApolloWrapper>{() => <ShareView />}</NoAuthApolloWrapper>
      </Route>
      <Route path="/:id">
        <NoAuthApolloWrapper>{() => <Warp404 />}</NoAuthApolloWrapper>
      </Route>
      <Route
        path="/"
        component={() => {
          window.location.href = 'https://www.warp.dev';
          return null;
        }}
      />
    </Switch>
  );
};

export default App;
