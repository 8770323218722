import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { User } from './AuthenticatedView';
import Warp404 from './404';
import AuthView from './auth/AuthView';

export const TEAM_INFO_FROM_EMAIL_INVITE_CODE_QUERY = gql`
  query GetTeamInfoFromEmailInviteCode(
    $input: GetTeamInfoFromEmailInviteCodeInput!
  ) {
    getTeamInfoFromEmailInviteCode(input: $input) {
      name
      uid
      emailInvitation {
        inviteeEmail
        expired
      }
      inviterEmail
      inviterDisplayName
      inviterPhotoUrl
      teamAcceptingInvites
    }
  }
`;

interface TeamLoginProps {
  user: User | undefined;
}

/**
 * `SignInAndJoinTeamWithInviteEmailView` allows users to view an email invite and sign up
 * for Warp. On success, they will be redirected to a logged in page with a download button.
 */
const SignInAndJoinTeamWithInviteEmailViewV2 = ({ user }: TeamLoginProps) => {
  const { emailAuthCode } = useParams<{
    emailAuthCode: string;
  }>();
  const [
    teamNameFromInviteCode,
    setTeamNameFromInviteCode,
  ] = useState<string>();

  // Check if the team code is valid.
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(TEAM_INFO_FROM_EMAIL_INVITE_CODE_QUERY, {
    variables: { input: { emailAuthCode } },
    onCompleted(data) {
      if (data?.getTeamInfoFromEmailInviteCode) {
        setTeamNameFromInviteCode(data.getTeamInfoFromEmailInviteCode.name);
      }
    },
  });

  if (
    !inviteCodeQueryLoading &&
    (!teamNameFromInviteCode || inviteCodeInvalid)
  ) {
    if (user?.isTelemetryEnabled) {
      window.rudderanalytics.track(
        'Visited Team Email Invite with Invalid Code',
        {
          emailAuthCode,
        }
      );
    }
    return <Warp404 />;
  }

  if (user) {
    return <Navigate to={`/team_invite/${emailAuthCode}`} replace />;
  }

  return (
    <AuthView
      headerContent={
        <>
          You&apos;ve been invited to join the{' '}
          <span className="font-weight-600">{teamNameFromInviteCode}</span> team
          on Warp.
        </>
      }
      showLoadingState={inviteCodeQueryLoading}
      buttonTrackingObject={{
        segmentMessage: 'Click Sign Up Button on Referred Signup View',
        googleAnalyticsMessage: 'Sign Up on Referred Sign Up View',
        googleAnalyticsCategory: 'Sign Up',
      }}
      user={user}
    />
  );
};

export default SignInAndJoinTeamWithInviteEmailViewV2;
