import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';

const UpgradeViewDisabled = () => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>
        <div className="font-weight-600">
          Oops! Upgrade unavailable right now
        </div>
      </ModalContainerHeader>
      <ModalContainerBody>
        <div>
          We&apos;ve hit a snag with our upgrade system, which means upgrades to
          the team plan aren&apos;t available at the moment.
        </div>
        <div>
          We apologize for any inconvenience this may cause. Our team is working
          diligently to resolve the issue.
        </div>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default UpgradeViewDisabled;
