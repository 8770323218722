import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/browser';
import {
  AuthError,
  AuthErrorCodes,
  AuthProvider,
  EmailAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  getRedirectResult,
  isSignInWithEmailLink,
  linkWithCredential,
  linkWithPopup,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { gql, useLazyQuery } from '@apollo/client';
import { User } from '../AuthenticatedView';
import firebase from '../utils/firebase';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import LoadingScreen from '../loading-screen/LoadingScreen';
import AuthErrorView from './AuthErrorView';
import TermsOfService from './TermsOfService';
import AuthForm from './AuthForm';
import EmailSentView from './EmailSentView';
import SsoAuthForm from './SsoAuthForm';
import {
  AuthErrorType,
  AuthState,
  AuthTrackingObject,
  LOCAL_STORAGE_USER_KEY,
} from './auth-helper';
import { ON_LOGIN_REDIRECT_QUERY_KEY } from '../utils/redirectAfterLogin';
import AuthHelpView from './AuthHelpView';
import './auth-view.css';

export const GET_ORGANIZATION = gql`
  query getOrganization($input: GetOrganizationInput!) {
    getOrganization(input: $input) {
      organizationId
      ssoEnabled
    }
  }
`;

interface AuthViewProps {
  // An object containing strings that should get sent to Segment/GA whenever one of the auth
  // buttons is clicked.
  buttonTrackingObject: AuthTrackingObject;
  // Provider IDs that should show up in the auth form.
  // Use this if you know the prospective user's email ahead of time, to prevent them from
  // choosing an auth method that would result in an AuthErrorCodes.NEED_CONFIRMATION error.
  // Defaults to all providers.
  enabledProviders?: string[];
  // The initial content for the header, e.g. "Sign up for Warp" or "Mary invited you to try Warp"
  headerContent: JSX.Element;
  // Whether or not the invoker of this component is still loading asynchronous data.
  // The auth view will show a loading message if so to prevent flickering.
  showLoadingState: boolean;
  // The user from the auth middleware. Undefined if there's no active auth session.
  user: User | undefined;
  // A function to call when the user has successfully linked their anonymous account to a real one.
  // Note that we need to explicitly call this because the onAuthStateChanged firebase
  // callback doesn't get invoked on linking, but we need to trigger a call to getOrCreateUser
  setAnonymousUserLinked?: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => Promise<void>;
}

/**
 * AuthView is the box which contains the entirety of the auth flow, for either new or existing
 * users. It handles the flow of potential auth mechanisms (e.g. using an email and getting sent a
 * confirmation email, or trying to sign in via SSO) as well as renders our TOS and any errors.
 */
const AuthView = ({
  buttonTrackingObject,
  enabledProviders,
  headerContent,
  showLoadingState,
  user,
  setAnonymousUserLinked,
  logout,
}: AuthViewProps) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const redirectPath = queryParams.get(ON_LOGIN_REDIRECT_QUERY_KEY);
  const auth = useMemo(() => getAuth(firebase), []);

  const [currentAuthState, setCurrentAuthState] = useState<AuthState>(
    AuthState.AuthForm
  );
  const [authError, setAuthError] = useState<AuthErrorType | undefined>();
  const [previousProviderId, setPreviousProviderId] = useState<
    string | undefined
  >();
  const [emailInput, setEmailInput] = useState<string>('');
  const [getOrganization] = useLazyQuery(GET_ORGANIZATION);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Call this function whenever you get an error from a Firebase API function.
  const handleFirebaseAuthError = useCallback(
    async (error: AuthError, provider?: AuthProvider) => {
      switch (error.code) {
        // The user has already created an account with a different provider.
        // Determine which provider they had previously used.
        case AuthErrorCodes.NEED_CONFIRMATION: {
          const pendingEmail = error.customData.email || '';
          const methods = await fetchSignInMethodsForEmail(auth, pendingEmail);
          const previousProvider = methods[0];
          if (previousProvider) {
            setAuthError(AuthErrorType.AuthedWithDifferentProvider);
            setPreviousProviderId(previousProvider);
          }
          break;
        }
        // The popup was blocked. Fallback to redirect login.
        case AuthErrorCodes.POPUP_BLOCKED: {
          if (provider) {
            Sentry.addBreadcrumb({
              category: 'auth',
              message:
                'Sign in popup was blocked--falling back to the redirect sign in flow',
              level: 'info',
            });

            // This promise never returns since it redirects the page to the auth provider to log them in. Once login
            // is complete, it redirects back to the page, which is captured by the `getRedirectResult` callback.
            await signInWithRedirect(auth, provider);
            break;
          } else {
            captureException(error);
            setAuthError(AuthErrorType.Generic);
          }
          break;
        }
        // We have Firebase blocking functions enabled which validate on both user creation and sign-in that
        // the user is authenticating in the proper way. If they determine that the user should not be authed,
        // they will throw one of these INTERNAL_ERRORs.
        case AuthErrorCodes.INTERNAL_ERROR: {
          // This particular error message maps to the Firebase blocking function's error returned
          // when a user must auth through SSO, but did not. This text must match the cloud function code's!
          // https://github.com/warpdotdev/eng-firebase-functions/blob/main/functions/src/index.ts#L61
          if (error.message.includes('Must auth using SSO')) {
            setAuthError(AuthErrorType.MustUseSso);
          } else {
            captureException(error);
            setAuthError(AuthErrorType.Generic);
          }
          break;
        }
        // The user is attempting to link but has already created an account with a different email.
        case AuthErrorCodes.EMAIL_EXISTS: {
          captureException(error);
          setAuthError(AuthErrorType.EmailAlreadyExists);
          break;
        }
        default: {
          captureException(error);
          setAuthError(AuthErrorType.Generic);
        }
      }
    },
    [auth]
  );

  // Calls to Firebase's API to complete sign in via email.
  // Call this function when you know that the email the user originally inputted matches
  // the one encoded in the authorization URL, either because you checked that the local storage
  // values match, or because the user inputted their email again.
  const completeEmailSignIn = useCallback(
    async (email: string) => {
      try {
        setIsLoading(true);
        if (user?.anonymousUserType) {
          const credential = EmailAuthProvider.credentialWithLink(
            email,
            window.location.href
          );
          await linkWithCredential(auth.currentUser!, credential);
          setAnonymousUserLinked?.(true);
        } else {
          await signInWithEmailLink(auth, email, window.location.href);
        }
        window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      } catch (error: any) {
        handleFirebaseAuthError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [auth, user, handleFirebaseAuthError, setAnonymousUserLinked]
  );

  // Run a one-time function to check if the URL we're on includes the information
  // from Firebase necessary to authenticate the user.
  useEffect(() => {
    const checkValidAuth = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const emailFromLocalStorage = window.localStorage.getItem(
          LOCAL_STORAGE_USER_KEY
        );
        if (emailFromLocalStorage) {
          await completeEmailSignIn(emailFromLocalStorage);
        } else {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again.
          setCurrentAuthState(AuthState.NeedsConfirmEmail);
        }
      } else {
        // Waits for a redirect from a Firebase auth provider if the user tried to sign in with a
        // redirect. This is a no-op if the user never initiated a sign in via a redirect.
        try {
          await getRedirectResult(auth);
        } catch (error: any) {
          handleFirebaseAuthError(error);
        }
      }
    };

    checkValidAuth();
  }, [auth, completeEmailSignIn, handleFirebaseAuthError]);

  const isUserAnonymous = !!user?.isAnonymous;
  // If the auth middleware returned us a non-anonymous user, they're already logged in.
  // Kick them to the logged in screen.
  if (user && !isUserAnonymous) {
    const redirectTo =
      redirectPath ||
      `/logged_in/remote?is_user_new=${
        user.isUserNew
      }&${queryParams.toString()}`;

    return <Navigate to={redirectTo} replace />;
  }

  // The component rendering us might still be fetching information, like the validity of a
  // referral code, or we might be asychronously loading data from Firebase. If either are
  // true, we'll return a loading UI here.
  if (showLoadingState || isLoading) {
    return <LoadingScreen />;
  }

  const trackAuthButtonClicked = () => {
    window.rudderanalytics.track(buttonTrackingObject.segmentMessage);
    ReactGA.event({
      action: 'button_clicked',
      category: buttonTrackingObject.googleAnalyticsCategory,
      label: buttonTrackingObject.googleAnalyticsMessage,
    });
  };

  // Attempt to sign in or link user with an SSO provider, via popup.
  const signInWithProvider = async (provider: AuthProvider) => {
    trackAuthButtonClicked();

    try {
      if (isUserAnonymous) {
        await linkWithPopup(auth.currentUser!, provider);
        setAnonymousUserLinked?.(true);
      } else {
        await signInWithPopup(auth, provider);
      }
    } catch (err: any) {
      await handleFirebaseAuthError(err, provider);
    }
  };

  // Attempts to sign the user in via a generic SSO connection (not Google/GitHub).
  const authWithSso = async (): Promise<void> => {
    setAuthError(undefined);

    const response = await getOrganization({
      variables: { input: { email: emailInput } },
    });
    const { organizationId, ssoEnabled } = response?.data?.getOrganization;
    if (organizationId && ssoEnabled) {
      const provider = new OAuthProvider('oidc.workos');
      provider.addScope('user:email');
      provider.setCustomParameters({
        organization: organizationId,
      });
      await signInWithProvider(provider);
    } else {
      setAuthError(AuthErrorType.NoSsoConnection);
    }
  };

  // Uses Firebase to send an email with an auth link to the user.
  const sendEmail = async () => {
    setAuthError(undefined);

    const actionCodeSettings = {
      url: window.location.href,
      // This must be true in order to support Email magic links.
      // See https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address.
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, emailInput, actionCodeSettings);
      setCurrentAuthState(AuthState.EmailSent);
      // Set the user's email in local storage so that we don't have to ask the user to confirm their email once they
      // click the link. See https://firebase.google.com/docs/auth/web/email-link-auth#security_concerns for background
      // on why we have to confirm the user's email.
      window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, emailInput);
    } catch (err: any) {
      await handleFirebaseAuthError(err);
    }
  };

  const ssoButtonClicked = (): Promise<void> => {
    setAuthError(undefined);
    setCurrentAuthState(AuthState.Sso);
    return Promise.resolve();
  };

  const backClicked = () => {
    setAuthError(undefined);
    setCurrentAuthState(AuthState.AuthForm);
  };

  let currentIcon;
  let currentHeader;
  let currentBody;

  switch (currentAuthState) {
    case AuthState.EmailSent:
      currentIcon = ModalContainerIconType.Check;
      currentHeader = 'Sign in email sent';
      currentBody = (
        <EmailSentView email={emailInput} resendEmail={sendEmail} />
      );
      break;
    case AuthState.Sso:
      currentIcon = ModalContainerIconType.Logo;
      currentHeader = 'Sign in with SSO';
      currentBody = (
        <SsoAuthForm
          email={emailInput}
          setEmail={setEmailInput}
          authWithSso={authWithSso}
          onBackClicked={backClicked}
        />
      );
      break;
    case AuthState.NeedsConfirmEmail:
      currentIcon = ModalContainerIconType.Logo;
      currentHeader = 'Re-enter your email address to continue';
      currentBody = (
        <AuthForm
          email={emailInput}
          setEmail={setEmailInput}
          onEmailSubmit={() => completeEmailSignIn(emailInput)}
          onSsoButtonClicked={ssoButtonClicked}
          signInWithProvider={signInWithProvider}
          // TODO: should this only be email?
          enabledProviders={enabledProviders!}
        />
      );
      break;
    default:
      currentIcon = ModalContainerIconType.Logo;
      currentHeader = headerContent;
      currentBody = (
        <AuthForm
          email={emailInput}
          setEmail={setEmailInput}
          onEmailSubmit={sendEmail}
          onSsoButtonClicked={ssoButtonClicked}
          signInWithProvider={signInWithProvider}
          enabledProviders={enabledProviders!}
        />
      );
  }

  return (
    <ModalContainer iconType={currentIcon}>
      <ModalContainerHeader>{currentHeader}</ModalContainerHeader>
      <ModalContainerBody>
        {currentBody}
        <div className="auth-view-footer">
          <TermsOfService />
          <AuthHelpView />
          <AuthErrorView
            error={authError}
            previousProviderId={previousProviderId}
            logout={logout}
            isAnonymous={isUserAnonymous}
          />
        </div>
      </ModalContainerBody>
    </ModalContainer>
  );
};

AuthView.defaultProps = {
  enabledProviders: ['password', 'google.com', 'github.com', 'oidc.workos'],
  setAnonymousUserLinked: () => {},
};

export default AuthView;
