import React, { useState } from 'react';

import { ansiToText } from 'anser';
import vertMenu from './assets/more_vert.svg';
import { BlockRenderInfo } from './block';
import copyText from './utils/copy-block';

const BlockDropdownMenu = ({
  block,
  showCommand,
  showOutput,
  isEmbed,
}: BlockRenderInfo) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  function createContextMenuCopyButton(
    textToCopy: string,
    buttonLabel: string
  ) {
    return (
      <button
        type="button"
        onClick={() => {
          copyText(textToCopy);
          setDropdownOpen(false);
          window.rudderanalytics.track(
            `Share View: View Dropdown Menu Item Selected`,
            {
              buttonLabel,
              isEmbed,
            }
          );
        }}
      >
        {buttonLabel}
      </button>
    );
  }

  return (
    <details className="dropdown" open={isDropdownOpen}>
      <summary className="top-right">
        <button
          className="dropdown-button"
          type="button"
          onClick={() => setDropdownOpen(!isDropdownOpen)}
        >
          <img
            src={vertMenu}
            alt="context menu"
            className="context-menu-icon"
          />
        </button>
      </summary>
      <div className="dropdown-content">
        <ul className="context-menu-list">
          {block.showPrompt &&
            createContextMenuCopyButton(
              ansiToText(block.stylizedPrompt),
              'Copy prompt'
            )}
          {showCommand &&
            createContextMenuCopyButton(block.command, 'Copy command')}
          {showOutput &&
            createContextMenuCopyButton(block.output, 'Copy output')}
        </ul>
      </div>
    </details>
  );
};

export default BlockDropdownMenu;
