import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import Warp404 from './404';
import { User } from './ApolloWrapper';
import AlreadyInCurrentTeam from './join-team/AlreadyInCurrentTeam';
import AlreadyInOtherTeam from './join-team/AlreadyInOtherTeam';
import ExpiredInvite from './join-team/ExpiredInvite';
import { Inviter } from './join-team/InviterHeading';
import JoinTeam from './join-team/JoinTeam';
import SuccessfulJoin from './join-team/SuccessfulJoin';
import WrongEmailAddress from './join-team/WrongEmailAddress';
import ContactAdminToUpgrade from './join-team/ContactAdminToUpgrade';
import SignInAndJoinTeamWithInviteEmailView, {
  TEAM_INFO_FROM_EMAIL_INVITE_CODE_QUERY,
} from './SignInAndJoinTeamWithInviteEmailView';
import WarpError from './WarpError';
import './login.css';

// 7 days in milliseconds
const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000;

export interface JoinTeamProps {
  user: User;
  logout: () => void;
}

export interface Team {
  uid: String;
  name: String;
}

export const JOIN_TEAM_WITH_INVITE_EMAIL = gql`
  mutation joinTeamWithInviteEmail($input: JoinTeamWithInviteEmailInput!) {
    joinTeamWithInviteEmail(input: $input) {
      success
    }
  }
`;

export const USER_TEAMS_QUERY = gql`
  query GetUserTeams {
    user {
      teams {
        name
        uid
      }
    }
  }
`;

const JoinTeamWithInviteEmailView = ({
  user,
  logout: logOut,
}: JoinTeamProps) => {
  const { emailAuthCode } = useParams<{
    emailAuthCode: string;
  }>();

  const client = useApolloClient();

  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const [
    teamNameFromInviteCode,
    setTeamNameFromInviteCode,
  ] = useState<string>();
  const [teamUIDFromInviteCode, setTeamUIDFromInviteCode] = useState<string>();
  const [inviterFromInviteCode, setInviterFromInviteCode] = useState<Inviter>();
  const [
    inviteeEmailFromInviteCode,
    setInviteeEmailFromInviteCode,
  ] = useState<string>();
  const [
    createdAtFromInviteCode,
    setCreatedAtFromInviteCode,
  ] = useState<string>();
  const [teamAcceptingInvites, setTeamAcceptingInvites] = useState(Boolean);

  const [userTeamNames, setUserTeamNames] = useState<string[]>();
  const [userTeamUIDs, setUserTeamUIDs] = useState<string[]>();

  // Mutation to join a team. Doesn't execute until `joinTeamWithInviteEmailMutation` is invoked.
  const [joinTeamWithInviteEmailMutation] = useMutation(
    JOIN_TEAM_WITH_INVITE_EMAIL,
    {
      client,
      variables: { input: { emailAuthCode } },
      onCompleted(data) {
        if (data?.joinTeamWithInviteEmail.success) {
          setJoinedSuccessfully(true);
        }
      },
      onError(err) {
        err.graphQLErrors.forEach((error) => {
          // eslint-disable-next-line no-alert
          alert(`Failed to join team. ${error.message}`);
        });
      },
    }
  );

  // Check if the invite code is valid.
  // Skip the query if the user is not even logged in.
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(TEAM_INFO_FROM_EMAIL_INVITE_CODE_QUERY, {
    skip: !user,
    variables: { input: { emailAuthCode } },
    onCompleted(data) {
      if (data?.getTeamInfoFromEmailInviteCode) {
        setTeamNameFromInviteCode(data.getTeamInfoFromEmailInviteCode.name);
        setTeamUIDFromInviteCode(data.getTeamInfoFromEmailInviteCode.uid);
        setInviterFromInviteCode({
          name: data.getTeamInfoFromEmailInviteCode.inviterDisplayName,
          email: data.getTeamInfoFromEmailInviteCode.inviterEmail,
          photoUrl: data.getTeamInfoFromEmailInviteCode.inviterPhotoUrl,
        });
        setInviteeEmailFromInviteCode(
          data.getTeamInfoFromEmailInviteCode.emailInvitation.inviteeEmail
        );
        setCreatedAtFromInviteCode(
          data.getTeamInfoFromEmailInviteCode.emailInvitation.createdAt
        );
        setTeamAcceptingInvites(
          data.getTeamInfoFromEmailInviteCode.teamAcceptingInvites
        );
      }
    },
  });

  // Check if the user is already part of any teams.
  // For now, we limit the user to a single team.
  // Skip the query if the user is not even logged in.
  const { loading: userTeamsQueryLoading } = useQuery(USER_TEAMS_QUERY, {
    skip: !user,
    onCompleted(data) {
      setUserTeamNames(data?.user?.teams.map((team: Team) => team.name));
      setUserTeamUIDs(data?.user?.teams.map((team: Team) => team.uid));
    },
  });

  // Show sign in page if user not logged in
  if (!user) {
    return <SignInAndJoinTeamWithInviteEmailView user={user} />;
  }

  if (inviteCodeQueryLoading || userTeamsQueryLoading) {
    return <WarpError error="Loading..." />;
  }

  function currentTeamName(): string | undefined {
    return userTeamNames?.[0];
  }

  function isAlreadyInCurrentTeam(teamUID: string): boolean {
    return userTeamUIDs?.includes(teamUID) ?? false;
  }

  function isTimestampOlderThan7Days(timestampString: string): boolean {
    const timestampDate = new Date(timestampString);
    const currentDate = new Date();
    const timeDifferenceMs = currentDate.getTime() - timestampDate.getTime();
    return timeDifferenceMs > SEVEN_DAYS_MS;
  }

  if (
    inviteCodeInvalid ||
    !teamNameFromInviteCode ||
    !createdAtFromInviteCode ||
    !inviteeEmailFromInviteCode
  ) {
    return <Warp404 />;
  }

  // If the user has managed to successfully join...
  if (joinedSuccessfully) {
    return <SuccessfulJoin teamNameFromInviteCode={teamNameFromInviteCode} />;
  }

  // Case 1: user is already in the team
  if (teamUIDFromInviteCode) {
    if (isAlreadyInCurrentTeam(teamUIDFromInviteCode)) {
      return (
        <AlreadyInCurrentTeam teamNameFromInviteCode={teamNameFromInviteCode} />
      );
    }
  }

  // Case 2: If the team doesn't have space for more members
  if (!teamAcceptingInvites) {
    return (
      <ContactAdminToUpgrade
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 3: Invitation create time is > 7 days (expired)
  if (
    createdAtFromInviteCode &&
    isTimestampOlderThan7Days(createdAtFromInviteCode)
  ) {
    return (
      <ExpiredInvite
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 4: Authenticated user's email does not match invitee email
  if (inviteeEmailFromInviteCode && inviteeEmailFromInviteCode !== user.email) {
    return (
      <WrongEmailAddress
        inviterFromInviteCode={inviterFromInviteCode}
        inviteeEmailFromInviteCode={inviteeEmailFromInviteCode}
        logOut={logOut}
        teamNameFromInviteCode={teamNameFromInviteCode}
        userEmail={user.email}
      />
    );
  }

  // Case 5: user is already in a team
  const currTeamName = currentTeamName();
  if (currTeamName) {
    return (
      <AlreadyInOtherTeam
        existingTeamName={currTeamName}
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // else... allow them to join the team and open Warp
  return (
    <JoinTeam
      inviterFromInviteCode={inviterFromInviteCode}
      onClickFunction={() => joinTeamWithInviteEmailMutation()}
      teamNameFromInviteCode={teamNameFromInviteCode}
      userEmail={user.email}
    />
  );
};

export { JoinTeamWithInviteEmailView };
