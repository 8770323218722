import React, { useEffect, useState } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CHECK_AND_RECORD_OBJECT_ACCESS_MUTATION } from './queries/CloudObject';

import { User } from './AuthenticatedView';
import createRedirectAfterLoginPath from './utils/redirectAfterLogin';
import LoadingScreen from './loading-screen/LoadingScreen';
import WasmView from './WasmView';
import FullPageModal from './FullPageModal';
import RequestAccessModal from './RequestAccessModal';

enum AllowedObjectTypes {
  Notebook = 'notebook',
  Workflow = 'workflow',
  EnvironmentVariables = 'env-vars',
}

interface DriveViewProps {
  user: User;
}

function getAppLaunchURL(
  objectType: AllowedObjectTypes,
  objectUID: string
): string {
  return `${process.env.REACT_APP_DEFAULT_SCHEME}://drive/${objectType}?id=${objectUID}`;
}

enum DriveViewState {
  Loading,
  Errored,
  ObjectNotFound,
  ObjectAccessDenied,
  ObjectAccessGranted,
}

function mapObjectTypeToEnum(objectType: string): AllowedObjectTypes | null {
  switch (objectType) {
    case AllowedObjectTypes.Notebook:
      return AllowedObjectTypes.Notebook;
    case AllowedObjectTypes.Workflow:
      return AllowedObjectTypes.Workflow;
    case AllowedObjectTypes.EnvironmentVariables:
      return AllowedObjectTypes.EnvironmentVariables;
    default:
      return null;
  }
}

function getObjectUID(objectSlug: string): string {
  const parts = objectSlug.split('-');
  return parts[parts.length - 1];
}

const NOT_FOUND_MESSAGE = "The resource you're looking for could not be found.";

const DriveView = ({ user }: DriveViewProps) => {
  // TODO figure out the correct analytics
  window.rudderanalytics.track('Visited drive link view');
  const { pathname } = useLocation();
  const params = useParams<{ object_type: string; object_slug: string }>();

  const objectType = mapObjectTypeToEnum(params.object_type || '');
  const objectUID = getObjectUID(params.object_slug || '');

  const [driveViewState, setDriveViewState] = useState(DriveViewState.Loading);
  const [objectName, setObjectName] = useState<string | null>(null);

  const [checkAndRecordObjectAccessMutation] = useMutation(
    CHECK_AND_RECORD_OBJECT_ACCESS_MUTATION,
    {
      variables: { uid: objectUID },
      onCompleted: (data) => {
        if (data.checkAndRecordObjectAccess.isTrashed) {
          setDriveViewState(DriveViewState.ObjectNotFound);
        } else {
          setDriveViewState(DriveViewState.ObjectAccessGranted);
          setObjectName(data.checkAndRecordObjectAccess.objectName);
        }
      },
      onError: (error: any) => {
        if (error.message.startsWith('Not found')) {
          setDriveViewState(DriveViewState.ObjectNotFound);
        } else if (error.message.startsWith('Unauthorized')) {
          setDriveViewState(DriveViewState.ObjectAccessDenied);
        } else {
          setDriveViewState(DriveViewState.Errored);
        }
      },
    }
  );

  useEffect(() => {
    checkAndRecordObjectAccessMutation();
  }, [checkAndRecordObjectAccessMutation]);

  if (!user) {
    return <Navigate to={createRedirectAfterLoginPath(pathname)} replace />;
  }

  if (driveViewState === DriveViewState.Loading) {
    return <LoadingScreen />;
  }

  if (
    !objectType ||
    !objectUID ||
    driveViewState === DriveViewState.ObjectNotFound ||
    driveViewState === DriveViewState.Errored
  ) {
    return <FullPageModal error={NOT_FOUND_MESSAGE} />;
  }

  if (driveViewState === DriveViewState.ObjectAccessDenied) {
    return <RequestAccessModal objectUID={objectUID} />;
  }

  const splashPageMessage = `Open Warp to view ${
    objectType === AllowedObjectTypes.EnvironmentVariables
      ? 'these environment variables'
      : objectName ??
        `this ${objectType[0].toUpperCase() + objectType.slice(1)}`
  }`;

  if (objectType === AllowedObjectTypes.EnvironmentVariables) {
    return (
      <FullPageModal
        appLaunchUrl={getAppLaunchURL(objectType, objectUID)}
        message={splashPageMessage}
      />
    );
  }

  // Otherwise just return the wasm component
  return (
    <WasmView
      splashPageMessage={splashPageMessage}
      appLaunchUrl={getAppLaunchURL(objectType, objectUID)}
    />
  );
};

export default DriveView;
