import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams, Redirect } from 'react-router-dom';
import { User } from './ApolloWrapper';
import Warp404 from './404';
import AuthView from './auth/AuthView';

export const TEAM_INFO_FROM_INVITE_CODE_QUERY = gql`
  query GetTeamInfoFromInviteCode($code: String!) {
    getTeamInfoFromInviteCode(code: $code) {
      name
      uid
      approvedDomains
      isInviteLinkEnabled
      inviterEmail
      inviterDisplayName
      inviterPhotoUrl
      teamAcceptingInvites
    }
  }
`;

interface TeamLoginProps {
  user: User | undefined;
}

/**
 * `SignInAndJoinTeamView` allows users to view team invite and sign up for Warp.
 * On success, they will be redirected to a logged in page with a download button.
 */
const SignInAndJoinTeamViewV2 = ({ user }: TeamLoginProps) => {
  const { inviteCode } = useParams<{
    inviteCode: string;
  }>();
  const [
    teamNameFromInviteCode,
    setTeamNameFromInviteCode,
  ] = useState<string>();
  const [teamInviteLinkEnabled, setTeamInviteLinkEnabled] = useState(false);

  // Check if the team code is valid.
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(TEAM_INFO_FROM_INVITE_CODE_QUERY, {
    variables: { code: inviteCode },
    onCompleted(data) {
      if (data?.getTeamInfoFromInviteCode) {
        setTeamNameFromInviteCode(data.getTeamInfoFromInviteCode.name);
        setTeamInviteLinkEnabled(
          data.getTeamInfoFromInviteCode.isInviteLinkEnabled
        );
      }
    },
  });

  if (
    !inviteCodeQueryLoading &&
    (inviteCodeInvalid || !teamNameFromInviteCode || !teamInviteLinkEnabled)
  ) {
    if (user?.isTelemetryEnabled) {
      window.rudderanalytics.track('Visited Team Signup with Invalid Code', {
        inviteCode,
      });
    }
    return <Warp404 />;
  }

  if (user) {
    return <Redirect to={`/team/${inviteCode}`} />;
  }

  window.rudderanalytics.track('Visited Team Signup of Team', {
    teamNameFromInviteCode,
  });

  return (
    <AuthView
      headerContent={
        <>
          You&apos;ve been invited to join the{' '}
          <span className="font-weight-600">{teamNameFromInviteCode}</span> team
          on Warp.
        </>
      }
      showLoadingState={inviteCodeQueryLoading}
      buttonTrackingObject={{
        segmentMessage: 'Click Sign Up Button on Referred Signup View',
        googleAnalyticsMessage: 'Sign Up on Referred Sign Up View',
        googleAnalyticsCategory: 'Sign Up',
      }}
      user={user}
    />
  );
};

export default SignInAndJoinTeamViewV2;
