import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { User } from './ApolloWrapper';
import createRedirectAfterLoginPath from './utils/redirectAfterLogin';
import { isProd } from './utils/env';
import WasmComponent from './WasmComponent';

interface Props {
  user: User;
}

/**
 * React component that renders a Warp on Web session for development.
 *
 * This session does not (yet) have any terminal access.
 */
export default function DevSession({ user }: Props) {
  const { pathname } = useLocation();

  // This route isn't exposed in prod, but just in case, check here too.
  if (isProd()) {
    return <Redirect to="/" />;
  }

  // Make sure that the user is logged in
  if (!user) {
    return <Redirect to={createRedirectAfterLoginPath(pathname)} />;
  }

  return <WasmComponent />;
}
