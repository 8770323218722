import React, { useState } from 'react';
import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Warp404 from './404';
import { User } from './AuthenticatedView';
import { Inviter } from './join-team/InviterHeading';
import SuccessfulJoin from './join-team/SuccessfulJoin';
import AlreadyInCurrentTeam from './join-team/AlreadyInCurrentTeam';
import AlreadyInOtherTeam from './join-team/AlreadyInOtherTeam';
import DomainNotApproved from './join-team/DomainNotApproved';
import JoinTeam from './join-team/JoinTeam';
import ContactAdminToUpgrade from './join-team/ContactAdminToUpgrade';
import SignInAndJoinTeamView, {
  TEAM_INFO_FROM_INVITE_CODE_QUERY,
} from './SignInAndJoinTeamView';
import WarpError from './WarpError';
import './login.css';
import ModalContainer, {
  ModalContainerIconType,
} from './modal-container/ModalContainer';
import ModalContainerHeader from './modal-container/ModalContainerHeader';
import ModalContainerBody from './modal-container/ModalContainerBody';

export interface JoinTeamProps {
  user: User;
  logout: () => Promise<void>;
}

export interface Team {
  uid: String;
  name: String;
}

export const JOIN_TEAM = gql`
  mutation JoinTeam($code: String!) {
    joinTeamWithInviteCode(code: $code)
  }
`;

export const USER_TEAMS_QUERY = gql`
  query GetUserTeams {
    user {
      teams {
        name
        uid
      }
    }
  }
`;

const JoinTeamView = ({ user, logout }: JoinTeamProps) => {
  const { inviteCode } = useParams<{
    inviteCode: string;
  }>();

  const client = useApolloClient();

  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const [hasApprovedDomain, setHasApprovedDomain] = useState(false);
  const [
    teamNameFromInviteCode,
    setTeamNameFromInviteCode,
  ] = useState<string>();
  const [teamUIDFromInviteCode, setTeamUIDFromInviteCode] = useState<string>();
  const [inviterFromInviteCode, setInviterFromInviteCode] = useState<Inviter>();
  const [teamInviteLinkEnabled, setTeamInviteLinkEnabled] = useState(Boolean);
  const [teamAcceptingInvites, setTeamAcceptingInvites] = useState(Boolean);
  const [teamApprovedDomains, setTeamApprovedDomains] = useState<string[]>();
  const [userTeamNames, setUserTeamNames] = useState<string[]>();
  const [userTeamUIDs, setUserTeamUIDs] = useState<string[]>();

  function checkIfApprovedDomain(approvedDomains: string[]) {
    const domain = user?.email?.slice(user?.email?.lastIndexOf('@') + 1);
    if (
      approvedDomains?.length === 0 ||
      (domain !== undefined && approvedDomains?.includes(domain))
    ) {
      setHasApprovedDomain(true);
    }
  }

  // Mutation to join a team. Doesn't execute until `joinTeamMutation` is invoked.
  const [joinTeamMutation] = useMutation(JOIN_TEAM, {
    client,
    variables: { code: inviteCode },
    onCompleted(data) {
      if (data?.joinTeamWithInviteCode) {
        setJoinedSuccessfully(true);
      }
    },
    onError(err) {
      // Pop an error if failed to join team.
      err.graphQLErrors.forEach((error) => {
        // eslint-disable-next-line no-alert
        alert(`Failed to join team. ${error.message}`);
      });
    },
  });

  // Check if the invite code is valid.
  // Skip the query if the user is not even logged in.
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(TEAM_INFO_FROM_INVITE_CODE_QUERY, {
    skip: !user,
    variables: { code: inviteCode },
    onCompleted(data) {
      if (data?.getTeamInfoFromInviteCode) {
        setTeamNameFromInviteCode(data.getTeamInfoFromInviteCode.name);
        setTeamUIDFromInviteCode(data.getTeamInfoFromInviteCode.uid);
        setInviterFromInviteCode({
          name: data.getTeamInfoFromInviteCode.inviterDisplayName,
          email: data.getTeamInfoFromInviteCode.inviterEmail,
          photoUrl: data.getTeamInfoFromInviteCode.inviterPhotoUrl,
        });
        checkIfApprovedDomain(data.getTeamInfoFromInviteCode.approvedDomains);
        setTeamApprovedDomains(data.getTeamInfoFromInviteCode.approvedDomains);
        setTeamInviteLinkEnabled(
          data.getTeamInfoFromInviteCode.isInviteLinkEnabled
        );
        setTeamAcceptingInvites(
          data.getTeamInfoFromInviteCode.teamAcceptingInvites
        );
      }
    },
  });

  // Check if the user is already part of any teams.
  // For now, we limit the user to a single team.
  // Skip the query if the user is not even logged in.
  const { loading: userTeamsQueryLoading } = useQuery(USER_TEAMS_QUERY, {
    skip: !user,
    onCompleted(data) {
      setUserTeamNames(data?.user?.teams.map((team: Team) => team.name));
      setUserTeamUIDs(data?.user?.teams.map((team: Team) => team.uid));
    },
  });

  // Show sign in page if user not logged in
  if (!user) {
    return <SignInAndJoinTeamView user={user} logout={logout} />;
  }

  if (inviteCodeQueryLoading || userTeamsQueryLoading) {
    return <WarpError error="Loading..." />;
  }

  function currentTeamName(): string | undefined {
    return userTeamNames?.[0];
  }

  function isAlreadyInCurrentTeam(teamUID: string): boolean {
    return userTeamUIDs?.includes(teamUID) ?? false;
  }

  // Check for invalid invite code
  if (
    inviteCodeInvalid?.message ===
    'The owner of the invite code is no longer in the team'
  ) {
    return (
      <ModalContainer iconType={ModalContainerIconType.Warning}>
        <ModalContainerHeader>Expired link</ModalContainerHeader>
        <ModalContainerBody>This invite link has expired.</ModalContainerBody>
      </ModalContainer>
    );
  }
  if (inviteCodeInvalid || !teamInviteLinkEnabled) {
    return <Warp404 />;
  }

  // If the user has managed to successfully join...
  if (joinedSuccessfully) {
    return <SuccessfulJoin teamNameFromInviteCode={teamNameFromInviteCode} />;
  }

  // Case 1: user is already in the team
  if (teamUIDFromInviteCode) {
    if (isAlreadyInCurrentTeam(teamUIDFromInviteCode)) {
      return (
        <AlreadyInCurrentTeam teamNameFromInviteCode={teamNameFromInviteCode} />
      );
    }
  }

  // Case 2: If the team doesn't have space for more members
  if (!teamAcceptingInvites) {
    return (
      <ContactAdminToUpgrade
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 3: user is already in a team
  const currTeamName = currentTeamName();
  if (currTeamName) {
    return (
      <AlreadyInOtherTeam
        existingTeamName={currTeamName}
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 4: email domain is not approved
  if (!hasApprovedDomain) {
    return (
      <DomainNotApproved
        inviteCode={inviteCode || ''}
        setHasApprovedDomain={setHasApprovedDomain}
        setJoinedSuccessfully={setJoinedSuccessfully}
        teamApprovedDomains={teamApprovedDomains}
      />
    );
  }

  // else... allow them to join the team and open Warp
  return (
    <JoinTeam
      inviterFromInviteCode={inviterFromInviteCode}
      onClickFunction={() => joinTeamMutation()}
      teamNameFromInviteCode={teamNameFromInviteCode}
      userEmail={user.email}
    />
  );
};

export { JoinTeamView };
