import React from 'react';
import { User } from '../ApolloWrapper';
import './user-name-and-photo.css';
import '../login.css';

interface UserNameAndPhotoProps {
  user: User;
}

function userNameForDisplay(user: User) {
  return user.displayName || user.email;
}

const UserNameAndPhoto = ({ user }: UserNameAndPhotoProps) => {
  return (
    <>
      <div>You&lsquo;re logged in as</div>
      <div className="authed-user-container">
        {user.photoURL && (
          <img
            className="authed-user-image authed-user-image--inline"
            alt="Profile"
            src={user.photoURL}
          />
        )}
        <h2 className="authed-username-header">{userNameForDisplay(user)}</h2>
      </div>
    </>
  );
};

export default UserNameAndPhoto;
