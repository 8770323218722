import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  gql,
  useMutation,
  useApolloClient,
  useLazyQuery,
} from '@apollo/client';
import { User } from './ApolloWrapper';
import ModalContainer, {
  ModalContainerIconType,
} from './modal-container/ModalContainer';
import ModalContainerHeader from './modal-container/ModalContainerHeader';
import ModalContainerBody from './modal-container/ModalContainerBody';
import './user-data.css';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from './modal-container/ModalContainerButton';

export interface UserDataProps {
  user: User;
}

export const GET_USER_QUERY = gql`
  query GetUser {
    user {
      email
      teams {
        name
        creatorFirebaseUid
        members {
          email
        }
      }
    }
  }
`;

export const TRANSFER_TEAM_OWNERSHIP_MUTATION = gql`
  mutation TransferTeamOwnership($newOwnerEmail: String!) {
    transferTeamOwnership(newOwnerEmail: $newOwnerEmail)
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser {
    deleteUser {
      success
    }
  }
`;

enum FormStep {
  Initial,
  TransferTeamOwnership,
  ConfirmDelete,
  DeletionComplete,
  Errored,
}

const UserDataView = ({ user }: UserDataProps) => {
  const client = useApolloClient();
  const [currentStep, setCurrentStep] = useState(FormStep.Initial);
  const [newOwnerEmail, setNewOwnerEmail] = useState('');
  // We use a lazy query and explicitly disable caching to avoid the scenario where you try to
  // delete your data -> you transfer ownership -> you cancel -> you hit the delete button again.
  // If we didn't hard refetch, we would think you're still the admin, since the query hasn't changed.
  const [loadUser, { data: userData }] = useLazyQuery(GET_USER_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [transferTeamOwnershipMutation] = useMutation(
    TRANSFER_TEAM_OWNERSHIP_MUTATION,
    {
      client,
      variables: {
        newOwnerEmail,
      },
      onCompleted(data) {
        if (data?.transferTeamOwnership) {
          setCurrentStep(FormStep.ConfirmDelete);
        } else {
          setCurrentStep(FormStep.Errored);
        }
      },
      onError() {
        setCurrentStep(FormStep.Errored);
      },
    }
  );
  const [deleteUserMutation] = useMutation(DELETE_USER_MUTATION, {
    client,
    onCompleted(data) {
      if (data?.deleteUser?.success) {
        setCurrentStep(FormStep.DeletionComplete);
      } else {
        setCurrentStep(FormStep.Errored);
      }
    },
    onError() {
      setCurrentStep(FormStep.Errored);
    },
  });

  if (!user) return <Redirect to="/login" />;

  const deleteButtonClicked = async () => {
    const response = await loadUser();
    const team = response?.data?.user?.teams[0];
    const needsTeamTransfer =
      team &&
      team.members.length > 1 &&
      team.creatorFirebaseUid === user.firebaseUID;

    if (needsTeamTransfer) {
      setCurrentStep(FormStep.TransferTeamOwnership);
    } else {
      setCurrentStep(FormStep.ConfirmDelete);
    }
  };
  const cancelDataDeletion = () => {
    setCurrentStep(FormStep.Initial);
  };
  const transferTeam = () => {
    transferTeamOwnershipMutation();
  };

  const getContent = () => {
    if (currentStep === FormStep.Initial) {
      return {
        icon: ModalContainerIconType.Logo,
        headerText: 'What would you like to do with your data?',
        bodyContent: (
          <>
            {/* TODO: add this in when we support export
            <div>
              Click to download all of your data on Warp&apos;s servers.
            </div>
            <ModalContainerButton
              text={"Download"}
              type={ModalContainerButtonType.Button}
              treatment={ModalContainerButtonTreatment.Primary}
            />
            <div className="user-data-form-spacer" /> */}
            <div>Click to delete all of your data on Warp&apos;s servers.</div>
            <ModalContainerButton
              content={<>Delete</>}
              buttonType={ModalContainerButtonType.Button}
              treatment={ModalContainerButtonTreatment.FullWidth}
              accent={ModalContainerButtonAccent.Destructive}
              onClickFunction={() => deleteButtonClicked()}
            />
          </>
        ),
      };
    }
    if (currentStep === FormStep.ConfirmDelete) {
      return {
        icon: ModalContainerIconType.Alert,
        headerText: 'Are you sure you want to delete ALL your data?',
        bodyContent: (
          <>
            <div>
              This includes your account, shared blocks, settings, Warp Drive
              objects, team memberships, and any other data stored on Warp
              servers.
            </div>
            <div>
              This process is irreversible. You will no longer be able to use
              Warp.
            </div>
            <ModalContainerButton
              content={<>Yes, delete all my data</>}
              buttonType={ModalContainerButtonType.Button}
              treatment={ModalContainerButtonTreatment.FullWidth}
              accent={ModalContainerButtonAccent.Destructive}
              onClickFunction={() => deleteUserMutation()}
            />
            <ModalContainerButton
              content={<>Cancel</>}
              buttonType={ModalContainerButtonType.Button}
              treatment={ModalContainerButtonTreatment.FullWidth}
              accent={ModalContainerButtonAccent.Transparent}
              onClickFunction={() => cancelDataDeletion()}
            />
          </>
        ),
      };
    }
    if (currentStep === FormStep.TransferTeamOwnership) {
      const newOwnerEmailSelected = newOwnerEmail.length > 0;

      return {
        icon: ModalContainerIconType.Warning,
        headerText: `You are the admin of ${userData.user.teams[0].name}.`,
        bodyContent: (
          <>
            <div>
              Transfer ownership of the team to another team member to continue
              with deletion.
            </div>
            <select
              className="user-data-form-select"
              defaultValue="default"
              onChange={(e) => setNewOwnerEmail(e.currentTarget.value)}
            >
              <option value="default" disabled hidden>
                Select a team member
              </option>
              {userData?.user.teams[0].members
                .filter((member: any) => member.email !== userData.user.email)
                .map((member: any) => (
                  <option key={member.email} value={member.email}>
                    {member.email}
                  </option>
                ))}
            </select>
            <ModalContainerButton
              disabled={!newOwnerEmailSelected}
              content={<>Confirm</>}
              buttonType={ModalContainerButtonType.Button}
              treatment={ModalContainerButtonTreatment.FullWidth}
              accent={
                newOwnerEmailSelected
                  ? ModalContainerButtonAccent.Primary
                  : ModalContainerButtonAccent.Transparent
              }
              onClickFunction={() => transferTeam()}
            />
          </>
        ),
      };
    }
    if (currentStep === FormStep.DeletionComplete) {
      return {
        icon: ModalContainerIconType.Warning,
        headerText:
          'Your data deletion request has been received, and will be processed promptly.',
        bodyContent: <div>Thank you for your patience.</div>,
      };
    }

    return {
      icon: ModalContainerIconType.Warning,
      headerText: 'Oops! Something went wrong.',
      bodyContent: (
        <>
          <div>Please refresh the page and try again.</div>
          <div>
            If the issue persists, email us at privacy@warp.dev to continue with
            your request.
          </div>
        </>
      ),
    };
  };

  const content = getContent();

  return (
    <ModalContainer iconType={content?.icon}>
      <ModalContainerHeader>{content?.headerText}</ModalContainerHeader>
      <ModalContainerBody>{content?.bodyContent}</ModalContainerBody>
    </ModalContainer>
  );
};

export { UserDataView };
