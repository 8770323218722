import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import { getDefaultScheme } from '../utils/env';
import CheckoutButton from './CheckoutButton';

const UpgradeRequestSentView = () => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>Your request has been sent</ModalContainerHeader>
      <ModalContainerBody>
        <CheckoutButton
          label="Open Warp"
          onClickFunction={() => {
            window.location.replace(
              `${getDefaultScheme()}://team/desktop_redirect`
            );
          }}
        />
        <ModalContainerButton
          treatment={ModalContainerButtonTreatment.FullWidth}
          buttonType={ModalContainerButtonType.Button}
          accent={ModalContainerButtonAccent.Secondary}
          content={<>Back to plans</>}
          onClickFunction={() => {
            window.location.replace('/upgrade');
          }}
        />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default UpgradeRequestSentView;
