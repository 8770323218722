import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import OpenWarpButton from './OpenWarpButton';
import InviterHeading, { Inviter } from './InviterHeading';

export interface AlreadyInOtherTeamProps {
  existingTeamName: string;
  inviterFromInviteCode: Inviter | undefined;
  teamNameFromInviteCode: string | undefined;
}

const AlreadyInOtherTeam = ({
  existingTeamName,
  inviterFromInviteCode,
  teamNameFromInviteCode,
}: AlreadyInOtherTeamProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>
        <InviterHeading
          inviter={inviterFromInviteCode}
          teamName={teamNameFromInviteCode}
        />
      </ModalContainerHeader>
      <ModalContainerBody>
        <div>
          You are already in team{' '}
          <span className="font-weight-600 text-white">{existingTeamName}</span>
          . In order to join another team, you must first leave your current
          team.
        </div>
        <div>Multi-team support is coming soon.</div>
        <OpenWarpButton />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default AlreadyInOtherTeam;
