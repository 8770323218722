import { gql } from '@apollo/client';

// Request object access
const REQUEST_OBJECT_ACCESS = gql`
  mutation RequestObjectAccess($uid: ID!) {
    sendRequestObjectAccessEmail(input: { objectUID: $uid }) {
      success
    }
  }
`;

export default REQUEST_OBJECT_ACCESS;
