import React from 'react';
import { Redirect } from 'react-router-dom';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import TeamDiscoveryBox, { DiscoverableTeam } from './TeamDiscoveryBox';
import './team-discovery.css';
import { User } from '../ApolloWrapper';

interface TeamDiscoveryProps {
  user: User;
  goToNextPage: () => void;
  teams: DiscoverableTeam[];
}

const TeamDiscoveryContainer = ({
  user,
  goToNextPage,
  teams,
}: TeamDiscoveryProps) => {
  if (!user) {
    return <Redirect to="/login/remote" />;
  }
  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>
        Exciting news: we found your teammates!
      </ModalContainerHeader>
      <ModalContainerBody>
        {[
          ...teams
            ?.filter((team) => team.teamAcceptingInvites)
            .sort((a, b) => b.numMembers - a.numMembers),
          ...teams
            ?.filter((team) => !team.teamAcceptingInvites)
            .sort((a, b) => b.numMembers - a.numMembers),
        ].map((team) => (
          <TeamDiscoveryBox
            key={team.teamUid}
            team={team}
            goToNextPage={goToNextPage}
          />
        ))}
        <ModalContainerButton
          content={<>Skip</>}
          treatment={ModalContainerButtonTreatment.FullWidth}
          accent={ModalContainerButtonAccent.Transparent}
          onClickFunction={goToNextPage}
        />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default TeamDiscoveryContainer;
