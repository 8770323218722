import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import WarpError from '../WarpError';
import WarpAnsiBlock from './WarpAnsiBlock';
import { DisplaySetting, GetBlockQuery } from './block';
import ShareViewHeader from './ShareViewHeader';
import Warp404 from '../404';
import './block.css';

const BLOCK_ID_REGEX = new RegExp('^[0-9a-zA-Z]{22}$');

function ShareView() {
  const { id } = useParams<{ id: string }>();
  const idMatchesBlockRegex = id ? BLOCK_ID_REGEX.test(id) : false;
  const { loading, error, data } = useQuery(GetBlockQuery, {
    variables: { id },
    skip: !idMatchesBlockRegex,
  });

  React.useEffect(() => {
    // Only emit event on successful/completed render.
    if (!error && !loading) {
      window.rudderanalytics.track('Viewed Share View', { id });
    }
  }, [error, id, loading]);

  if (!idMatchesBlockRegex) {
    return <Warp404 />;
  }

  if (error) {
    if (error.message && error.message === 'Not found: no rows in result set') {
      return <Warp404 />;
    }
    return <WarpError error={error?.message} />;
  }
  if (loading) {
    return <WarpError error="Loading..." />;
  }

  const showCommand =
    data.getBlock.embedDisplaySetting === DisplaySetting.COMMAND ||
    data.getBlock.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  const showOutput =
    data.getBlock.embedDisplaySetting === DisplaySetting.OUTPUT ||
    data.getBlock.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  return (
    <div className="App-embed">
      <div className="share-view-wrapper">
        <ShareViewHeader />
        <WarpAnsiBlock
          blockRenderInfo={{
            block: data.getBlock,
            showCommand,
            showOutput,
            isEmbed: false,
          }}
          isEmbed={false}
        />
      </div>
    </div>
  );
}

export default ShareView;
