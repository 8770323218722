export enum AllowedCustomerTypes {
  FREE = 'FREE',
  SELF_SERVE = 'SELF_SERVE',
  PROSUMER = 'PROSUMER',
  LEGACY = 'LEGACY',
  ENTERPRISE = 'ENTERPRISE',
}

export enum StripeSubscriptionInterval {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum StripeSubscriptionPlan {
  PRO = 'PRO',
  TEAM = 'TEAM',
}
