import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { User } from '../ApolloWrapper';
import createRedirectAfterLoginPath from '../utils/redirectAfterLogin';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import { getDefaultScheme } from '../utils/env';

enum StripeSubscriptionPlan {
  PRO = 'PRO',
  TEAM = 'TEAM',
}

function mapPlanToEnum(plan: string): StripeSubscriptionPlan | null {
  switch (plan) {
    case 'PRO':
      return StripeSubscriptionPlan.PRO;
    case 'TEAM':
      return StripeSubscriptionPlan.TEAM;
    default:
      return null;
  }
}

const openIntent = () => {
  // Open the app to the team settings page.
  window.location.replace(`${getDefaultScheme()}://team/settings`);
};

export const CHECKOUT_SESSION_DETAILS_QUERY = gql`
  query checkoutSessionDetails($input: CheckoutSessionDetailsInput!) {
    checkoutSessionDetails(input: $input) {
      plan
    }
  }
`;

interface UpgradeConfirmationViewProps {
  user: User;
}

const UpgradeConfirmationView = ({ user }: UpgradeConfirmationViewProps) => {
  const client = useApolloClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');

  const [plan, setPlan] = useState<{
    plan: StripeSubscriptionPlan;
  }>();
  const [errored, setErrored] = useState(false);

  const { loading: checkoutSessionDetailsLoading } = useQuery(
    CHECKOUT_SESSION_DETAILS_QUERY,
    {
      skip: !user,
      client,
      variables: {
        input: {
          sessionId,
        },
      },
      onCompleted(data) {
        if (data?.checkoutSessionDetails?.plan) {
          const enumValue = mapPlanToEnum(data.checkoutSessionDetails.plan);
          if (enumValue == null) {
            setErrored(true);
            return;
          }
          setPlan({ plan: enumValue });
        } else {
          setErrored(true);
        }
      },
      onError() {
        setErrored(true);
      },
    }
  );

  if (!user) {
    return (
      <Redirect
        to={createRedirectAfterLoginPath(
          `/confirmation?session_id=${sessionId}`
        )}
      />
    );
  }

  if (errored) {
    return (
      <ModalContainer iconType={ModalContainerIconType.Alert}>
        <ModalContainerHeader>Error</ModalContainerHeader>
        <ModalContainerBody>
          <p>Something went wrong.</p>
        </ModalContainerBody>
      </ModalContainer>
    );
  }

  if (checkoutSessionDetailsLoading) {
    return (
      <ModalContainer iconType={ModalContainerIconType.Logo}>
        <ModalContainerHeader>Loading...</ModalContainerHeader>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>
        Welcome to the{' '}
        {plan?.plan === StripeSubscriptionPlan.PRO ? 'Pro' : 'Team'} plan!
      </ModalContainerHeader>
      <ModalContainerBody>
        <p>
          Thank you for your purchase. We&apos;re excited to help you experience
          the power of Warp. Please feel free to contact us at{' '}
          <ModalContainerButton
            content={<>support@warp.dev</>}
            treatment={ModalContainerButtonTreatment.Inline}
            accent={ModalContainerButtonAccent.Transparent}
            href="mailto:support@warp.dev"
          />{' '}
          with any questions.
        </p>
        <ModalContainerButton
          treatment={ModalContainerButtonTreatment.FullWidth}
          buttonType={ModalContainerButtonType.Button}
          accent={ModalContainerButtonAccent.Primary}
          content={<>Continue to Warp</>}
          onClickFunction={() => openIntent()}
        />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default UpgradeConfirmationView;
