interface FeatureFlags {
  REACT_APP_ENABLE_AUTH_REFRESH: boolean;
  REACT_APP_ENABLE_DRIVE_LINKABILITY: boolean;
  REACT_APP_ENABLE_SUBSCRIPTION_UPGRADE_PAGE: boolean;
  REACT_APP_ENABLE_SESSION_WEB_LINKS: boolean;
  REACT_APP_ENABLE_EMBEDDED_WASM: boolean;
  REACT_APP_ENABLE_PRO_PLAN: boolean;
  REACT_APP_ENABLE_ENV_VARS: boolean;
  REACT_APP_ENABLE_REQUEST_ACCESS_FLOW: boolean;
  REACT_APP_ENABLE_LINK_ANONYMOUS_USERS: boolean;
}

function loadFeatureFlags() {
  const featureFlags: FeatureFlags = {
    REACT_APP_ENABLE_AUTH_REFRESH:
      process.env.REACT_APP_ENABLE_AUTH_REFRESH === 'true',
    REACT_APP_ENABLE_DRIVE_LINKABILITY:
      process.env.REACT_APP_ENABLE_DRIVE_LINKABILITY === 'true',
    REACT_APP_ENABLE_SUBSCRIPTION_UPGRADE_PAGE:
      process.env.REACT_APP_ENABLE_SUBSCRIPTION_UPGRADE_PAGE === 'true',
    REACT_APP_ENABLE_SESSION_WEB_LINKS:
      process.env.REACT_APP_ENABLE_SESSION_WEB_LINKS === 'true',
    REACT_APP_ENABLE_EMBEDDED_WASM:
      process.env.REACT_APP_ENABLE_EMBEDDED_WASM === 'true',
    REACT_APP_ENABLE_PRO_PLAN: process.env.REACT_APP_ENABLE_PRO_PLAN === 'true',
    REACT_APP_ENABLE_ENV_VARS: process.env.REACT_APP_ENABLE_ENV_VARS === 'true',
    REACT_APP_ENABLE_REQUEST_ACCESS_FLOW:
      process.env.REACT_APP_ENABLE_REQUEST_ACCESS_FLOW === 'true',
    REACT_APP_ENABLE_LINK_ANONYMOUS_USERS:
      process.env.REACT_APP_ENABLE_LINK_ANONYMOUS_USERS === 'true',
  };

  return featureFlags;
}

const FEATURE_FLAGS = loadFeatureFlags();

function getDefaultScheme(): string {
  return process.env.REACT_APP_DEFAULT_SCHEME!.trim();
}

function isProd(): Boolean {
  return process.env.REACT_APP_DEFAULT_SCHEME === 'warp';
}

function isStaging(): Boolean {
  return process.env.REACT_APP_DEFAULT_SCHEME === 'warpdev';
}

export { getDefaultScheme, isProd, isStaging, FEATURE_FLAGS };
