import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useLocation, useHistory } from 'react-router-dom';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { User } from '../ApolloWrapper';
import createRedirectAfterLoginPath from '../utils/redirectAfterLogin';
import { ModalContainerIconType } from '../modal-container/ModalContainer';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import './upgrade.css';
import UpgradeViewDisabled from './UpgradeViewDisabled';
import UpgradeResultView from './UpgradeResultView';
import { FEATURE_FLAGS } from '../utils/env';
import ProAndTeamPlansView from './ProAndTeamPlansView';
import OnlyTeamPlansView from './OnlyTeamPlansView';
import {
  AllowedCustomerTypes,
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from './UpgradeEnums';
import UpgradeErrorView from './UpgradeErrorView';
import RequestUpgradeOverlayModal from './RequestUpgradeOverlayModal';
import MismatchedLoginModal from './MismatchedLoginModal';
import InvalidTeamLinkModal from './InvalidTeamLinkModal';

interface UpgradeViewProps {
  user: User;
  logout: () => Promise<void>;
}

export const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeamV2(input: $input) {
      __typename
      ... on CreateTeamOutput {
        team {
          uid
          creatorFirebaseUid
          billingMetadata {
            customerType
          }
          members {
            email
            firebase_uid
          }
        }
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;

export const STRIPE_CHECKOUT_SESSION_MUTATION = gql`
  mutation stripeCheckoutSessionWithIntervalAndPlan(
    $input: StripeCheckoutSessionWithIntervalAndPlanInput!
  ) {
    stripeCheckoutSessionWithIntervalAndPlan(input: $input) {
      __typename
      ... on StripeCheckoutSessionResult {
        url
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;

export const TEAM_PLAN_UPDATE_CONFIRMATION_PAGE_MUTATION = gql`
  mutation teamPlanUpdateConfirmationPage(
    $input: TeamPlanUpdateConfirmationPageInput!
  ) {
    teamPlanUpdateConfirmationPage(input: $input) {
      __typename
      ... on TeamPlanUpdateConfirmationPageSuccess {
        url
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;

export const STRIPE_BILLING_PORTAL_MUTATION = gql`
  mutation stripeBillingPortal($input: StripeBillingPortalInput!) {
    stripeBillingPortal(input: $input) {
      url
    }
  }
`;

export const GET_USER_QUERY = gql`
  query GetUser {
    user {
      email
      teams {
        uid
        creatorFirebaseUid
        billingMetadata {
          customerType
        }
        members {
          email
          firebase_uid
        }
      }
    }
  }
`;

export const SEND_UPGRADE_REQUEST_MUTATION = gql`
  mutation sendUpgradeRequest($input: SendUpgradeRequestInput!) {
    sendUpgradeRequest(input: $input) {
      __typename
      ... on SendUpgradeRequestSuccess {
        success
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;

function mapCustomerTypeToEnum(
  customerType: string
): AllowedCustomerTypes | null {
  switch (customerType) {
    case 'FREE':
      return AllowedCustomerTypes.FREE;
    case 'SELF_SERVE':
      return AllowedCustomerTypes.SELF_SERVE;
    case 'PROSUMER':
      return AllowedCustomerTypes.PROSUMER;
    case 'LEGACY':
      return AllowedCustomerTypes.LEGACY;
    case 'ENTERPRISE':
      return AllowedCustomerTypes.ENTERPRISE;
    default:
      return null;
  }
}

const UpgradeView = ({ user, logout }: UpgradeViewProps) => {
  const client = useApolloClient();
  const { teamUid, firebaseUid: clientUserId } = useParams<{
    teamUid: string;
    firebaseUid: string;
  }>();

  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const intervalParam = searchParams.get('interval');
  const planParam = searchParams.get('plan');

  // If stripe checkout session errored, then redirect user to error page.
  const [errored, setErrored] = useState(false);

  const [creatingTeam, setCreatingTeam] = useState(false);

  const [requestedUpgrade, setRequestedUpgrade] = useState(false);
  const [
    requestedInterval,
    setRequestedInterval,
  ] = useState<StripeSubscriptionInterval>(StripeSubscriptionInterval.MONTHLY);
  const [requestedPlan, setRequestedPlan] = useState<StripeSubscriptionPlan>(
    StripeSubscriptionPlan.TEAM
  );
  const [requestUpgradeReason, setRequestUpgradeReason] = useState('');

  // Flag to show "Creating a team..." message after creating a team and before
  // redirecting to the checkout session. Otherwise our UI would switch from
  // "Creating a team..." to "Loading..." before redirecting to the checkout session.
  const [
    loadingCheckoutSessionAfterCreatingTeam,
    setLoadingCheckoutSessionAfterCreatingTeam,
  ] = useState(false);

  // Additional flag to prevent flickering between generating checkout session and
  // redirecting to it.
  const [redirectingToPortal, setRedirectingToPortal] = useState(false);

  // Stores the teams that the user is a part of (along with its uid, creatorFirebaseUid, customerType, and # of members)
  const [teams, setTeams] = useState<
    {
      uid: string;
      creatorFirebaseUid: string;
      customerType: AllowedCustomerTypes;
      numMembers: number;
      adminEmail: string;
    }[]
  >([]);

  // There's a gap between `getUserQueryLoading` turning false and when `teams` is
  // populated, causing the UI to try and render with an empty `teams` array.
  // This flag is used to prevent that.
  const [userTeamsSet, setUserTeamsSet] = useState(false);

  // Selector for the subscription interval type (yearly or monthly)
  const [
    selectedSubscriptionInterval,
    setSelectedSubscriptionInterval,
  ] = useState<StripeSubscriptionInterval>(StripeSubscriptionInterval.MONTHLY);

  // For use in the create team mutation to determine which plan checkout session to
  // redirect to after team creation.
  const [
    selectedSubscriptionPlan,
    setSelectedSubscriptionPlan,
  ] = useState<StripeSubscriptionPlan>(StripeSubscriptionPlan.PRO);

  const handleSubscriptionIntervalToggle = (
    interval: StripeSubscriptionInterval
  ) => {
    setSelectedSubscriptionInterval(interval);
  };

  const { loading: getUserQueryLoading } = useQuery(GET_USER_QUERY, {
    skip: !user,
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setTeams(
        data?.user?.teams?.map(
          (team: {
            uid: any;
            creatorFirebaseUid: any;
            billingMetadata: { customerType: string };
            members: {
              firebase_uid: string;
              email: string;
            }[];
          }) => {
            const adminEmail = team.members.find(
              (member) => member.firebase_uid === team.creatorFirebaseUid
            )?.email;

            return {
              uid: team.uid,
              creatorFirebaseUid: team.creatorFirebaseUid,
              customerType: mapCustomerTypeToEnum(
                team.billingMetadata?.customerType
              ),
              numMembers: team.members.length,
              adminEmail,
            };
          }
        )
      );
      setUserTeamsSet(true);
    },
    onError() {
      setErrored(true);
      setUserTeamsSet(true);
    },
  });

  const [
    openStripeCheckoutSessionMutation,
    { loading: checkoutSessionUrlLoading },
  ] = useMutation(STRIPE_CHECKOUT_SESSION_MUTATION, {
    client,
    onCompleted(data) {
      if (data?.stripeCheckoutSessionWithIntervalAndPlan?.url) {
        window.location.href =
          data.stripeCheckoutSessionWithIntervalAndPlan.url;
      } else {
        setErrored(true);
      }
    },
    onError() {
      setErrored(true);
    },
  });
  const openStripeCheckoutSessionLink = (
    interval: StripeSubscriptionInterval,
    plan: StripeSubscriptionPlan
  ) => {
    setRedirectingToPortal(true);
    openStripeCheckoutSessionMutation({
      variables: {
        input: {
          teamUid,
          interval,
          plan,
        },
      },
    });
  };

  const [createTeamMutation, { loading: createTeamLoading }] = useMutation(
    CREATE_TEAM_MUTATION,
    {
      client,
      onCompleted(data) {
        if (data?.createTeamV2?.team) {
          const customerTypeFromResponse = mapCustomerTypeToEnum(
            data.createTeamV2.team.billingMetadata?.customerType
          );
          const customerType =
            customerTypeFromResponse ?? AllowedCustomerTypes.FREE;

          const adminEmail = data.createTeamV2.team.members.find(
            (member: { firebase_uid: string; email: string }) =>
              member.firebase_uid === data.createTeamV2.team.creatorFirebaseUid
          )?.email;

          setTeams([
            {
              uid: data.createTeamV2.team.uid,
              creatorFirebaseUid: data.createTeamV2.team.creatorFirebaseUid,
              customerType,
              numMembers: data.createTeamV2.team.members.length,
              adminEmail,
            },
          ]);

          const redirectToStripeCheckoutSession = () => {
            setCreatingTeam(false);

            // We can't call `openStripeCheckoutSessionLink` directly here because the
            // `teamUid` that it relies on is pulled from the URL path, and is null
            // (because they didn't have a team before this point). Instead, we'll
            // redirect them to the path with teamUid set along with the selected interval
            // and plan, and then handle redirecting to the checkout session in the next
            // render.
            history.push(
              `/upgrade/${data.createTeamV2.team.uid}?interval=${selectedSubscriptionInterval}&plan=${selectedSubscriptionPlan}`
            );
          };

          // Redirect to the checkout session for the newly created team after a delay (so
          // the user can see the "Creating a team..." message before being redirected to
          // the checkout session).
          setTimeout(redirectToStripeCheckoutSession, 1000);
        } else {
          setErrored(true);
          setCreatingTeam(false);
        }
      },
      onError() {
        setErrored(true);
        setCreatingTeam(false);
      },
    }
  );
  const createTeam = (plan: StripeSubscriptionPlan) => {
    setCreatingTeam(true);
    setSelectedSubscriptionPlan(plan);

    let newTeamName = 'My Team';
    if (user.displayName) newTeamName = `${user.displayName}'s Team`;
    else if (user.email) newTeamName = `${user.email}'s Team`;

    createTeamMutation({
      variables: {
        input: {
          name: newTeamName,
          entrypoint: 'UpgradePage',
        },
      },
    });
  };

  const [
    openTeamPlanUpdateConfirmationPageMutation,
    { loading: teamPlanUpdateConfirmationPageUrlLoading },
  ] = useMutation(TEAM_PLAN_UPDATE_CONFIRMATION_PAGE_MUTATION, {
    client,
    onCompleted(data) {
      if (data?.teamPlanUpdateConfirmationPage?.url) {
        window.location.href = data.teamPlanUpdateConfirmationPage.url;
      } else {
        setErrored(true);
      }
    },
    onError() {
      setErrored(true);
    },
  });
  const openTeamPlanUpdateConfirmationPageLink = (
    interval: StripeSubscriptionInterval
  ) => {
    setRedirectingToPortal(true);
    openTeamPlanUpdateConfirmationPageMutation({
      variables: {
        input: {
          teamUid,
          interval,
        },
      },
    });
  };

  const [
    openStripeBillingPortalMutation,
    { loading: billingPortalUrlLoading },
  ] = useMutation(STRIPE_BILLING_PORTAL_MUTATION, {
    client,
    variables: {
      input: {
        teamUid,
      },
    },
    onCompleted(data) {
      if (data?.stripeBillingPortal?.url) {
        window.location.href = data.stripeBillingPortal.url;
      } else {
        setErrored(true);
      }
    },
    onError() {
      setErrored(true);
    },
  });

  const [
    sendUpgradeRequestMutation,
    { loading: sendUpgradeRequestLoading },
  ] = useMutation(SEND_UPGRADE_REQUEST_MUTATION, {
    client,
    onCompleted(data) {
      if (data?.sendUpgradeRequest?.success) {
        window.location.replace('/upgrade_request_sent');
      } else {
        setErrored(true);
      }
    },
    onError() {
      setErrored(true);
    },
  });
  const sendUpgradeRequest = (
    requestedTeamUid: string,
    interval: StripeSubscriptionInterval,
    plan: StripeSubscriptionPlan,
    reason: string
  ) => {
    setRedirectingToPortal(true);
    sendUpgradeRequestMutation({
      variables: {
        input: {
          teamUid: requestedTeamUid,
          interval,
          plan,
          reason,
        },
      },
    });
  };
  const requestUpgradeFromAdmin = (
    interval: StripeSubscriptionInterval,
    plan: StripeSubscriptionPlan
  ) => {
    setRequestedUpgrade(true);
    setRequestedInterval(interval);
    setRequestedPlan(plan);
  };

  // If user presses escape key, close the upgrade request overlay.
  useEffect(() => {
    const handleEsc = (event: { key: string }) => {
      if (event.key === 'Escape') {
        setRequestedUpgrade(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  if (!FEATURE_FLAGS.REACT_APP_ENABLE_SUBSCRIPTION_UPGRADE_PAGE) {
    return <UpgradeViewDisabled />;
  }

  if (!user) {
    return (
      <Redirect
        to={createRedirectAfterLoginPath(
          teamUid ? `/upgrade/${teamUid}` : `/upgrade` // TODO(liam): replace when new /upgrade/user route created
        )}
      />
    );
  }

  if (errored) {
    return <UpgradeErrorView />;
  }

  if (creatingTeam || loadingCheckoutSessionAfterCreatingTeam) {
    return (
      <UpgradeResultView
        icon={ModalContainerIconType.Logo}
        headerText="Creating a team..."
        bodyContent={<></>}
      />
    );
  }

  if (
    getUserQueryLoading ||
    !userTeamsSet ||
    checkoutSessionUrlLoading ||
    createTeamLoading ||
    teamPlanUpdateConfirmationPageUrlLoading ||
    billingPortalUrlLoading ||
    redirectingToPortal ||
    sendUpgradeRequestLoading
  ) {
    return (
      <UpgradeResultView
        icon={ModalContainerIconType.Logo}
        headerText="Loading..."
        bodyContent={<></>}
      />
    );
  }

  // The interval and plan parameters are used to determine which checkout session to
  // redirect to after a team is auto-created. If these values are set in the URL, then
  // we'll immediately redirect to the corresponding checkout session.
  if (intervalParam && planParam) {
    setLoadingCheckoutSessionAfterCreatingTeam(true);
    openStripeCheckoutSessionLink(
      intervalParam as StripeSubscriptionInterval,
      planParam as StripeSubscriptionPlan
    );

    return (
      <UpgradeResultView
        icon={ModalContainerIconType.Logo}
        headerText="Creating a team..."
        bodyContent={<></>}
      />
    );
  }

  const isInAnyTeam = teams.length > 0;

  // If user is not on any team yet, we still show them a view to upgrade to Pro or Team.
  // The plan upgrade view defaults to 1 seat as the user is not in any team yet.
  // When a user selects a plan to upgrade to, we will create a team for them and redirect
  // them to the checkout session for the selected plan.
  if (!isInAnyTeam) {
    if (FEATURE_FLAGS.REACT_APP_ENABLE_PRO_PLAN) {
      return (
        <ProAndTeamPlansView
          isAdmin
          interval={selectedSubscriptionInterval}
          numMembers={1}
          monthlyButtonFunction={() =>
            handleSubscriptionIntervalToggle(StripeSubscriptionInterval.MONTHLY)
          }
          yearlyButtonFunction={() =>
            handleSubscriptionIntervalToggle(StripeSubscriptionInterval.YEARLY)
          }
          proPlanButtonFunction={() => createTeam(StripeSubscriptionPlan.PRO)}
          teamPlanButtonFunction={() => createTeam(StripeSubscriptionPlan.TEAM)}
        />
      );
    }

    return (
      <OnlyTeamPlansView
        isAdmin
        interval={selectedSubscriptionInterval}
        numMembers={1}
        monthlyButtonFunction={() =>
          handleSubscriptionIntervalToggle(StripeSubscriptionInterval.MONTHLY)
        }
        yearlyButtonFunction={() =>
          handleSubscriptionIntervalToggle(StripeSubscriptionInterval.YEARLY)
        }
        continueButtonFunction={() => createTeam(StripeSubscriptionPlan.TEAM)}
      />
    );
  }

  // If the user is logged into a different account on client vs browser, display error
  if (clientUserId && clientUserId !== user.firebaseUID) {
    return <MismatchedLoginModal user={user} logout={logout} />;
  }

  if (!teamUid) {
    // If no teamUid is provided then default to the first team.
    return <Redirect to={`/upgrade/${teams[0].uid}`} />;
  }

  // Get the team data for the team with uid = teamUid.
  const teamData = teams.find((team) => team.uid === teamUid);

  // Check if user is in the team specified by the team_uid parameter.
  if (!teamData) {
    return <InvalidTeamLinkModal logout={logout} />;
  }

  // User is admin of the team if the team's creatorFirebaseUid matches the user's firebaseUID.
  const isAdmin = teamData && teamData.creatorFirebaseUid === user.firebaseUID;

  if (isAdmin) {
    switch (teamData.customerType) {
      case AllowedCustomerTypes.FREE:
      case AllowedCustomerTypes.LEGACY:
        if (FEATURE_FLAGS.REACT_APP_ENABLE_PRO_PLAN) {
          return (
            <ProAndTeamPlansView
              isAdmin
              interval={selectedSubscriptionInterval}
              numMembers={teamData.numMembers}
              monthlyButtonFunction={() =>
                handleSubscriptionIntervalToggle(
                  StripeSubscriptionInterval.MONTHLY
                )
              }
              yearlyButtonFunction={() =>
                handleSubscriptionIntervalToggle(
                  StripeSubscriptionInterval.YEARLY
                )
              }
              proPlanButtonFunction={() =>
                openStripeCheckoutSessionLink(
                  selectedSubscriptionInterval,
                  StripeSubscriptionPlan.PRO
                )
              }
              teamPlanButtonFunction={() =>
                openStripeCheckoutSessionLink(
                  selectedSubscriptionInterval,
                  StripeSubscriptionPlan.TEAM
                )
              }
            />
          );
        }
        return (
          <OnlyTeamPlansView
            isAdmin
            interval={selectedSubscriptionInterval}
            numMembers={teamData.numMembers}
            monthlyButtonFunction={() =>
              handleSubscriptionIntervalToggle(
                StripeSubscriptionInterval.MONTHLY
              )
            }
            yearlyButtonFunction={() =>
              handleSubscriptionIntervalToggle(
                StripeSubscriptionInterval.YEARLY
              )
            }
            continueButtonFunction={() =>
              openStripeCheckoutSessionLink(
                selectedSubscriptionInterval,
                StripeSubscriptionPlan.TEAM
              )
            }
          />
        );
      // If on prosumer plan, only show the team plans.
      case AllowedCustomerTypes.PROSUMER:
        return (
          <OnlyTeamPlansView
            isAdmin
            interval={selectedSubscriptionInterval}
            numMembers={teamData.numMembers}
            monthlyButtonFunction={() =>
              handleSubscriptionIntervalToggle(
                StripeSubscriptionInterval.MONTHLY
              )
            }
            yearlyButtonFunction={() =>
              handleSubscriptionIntervalToggle(
                StripeSubscriptionInterval.YEARLY
              )
            }
            continueButtonFunction={() =>
              openTeamPlanUpdateConfirmationPageLink(
                selectedSubscriptionInterval
              )
            }
          />
        );
      // If on self-serve (team) plan, show link to billing portal.
      case AllowedCustomerTypes.SELF_SERVE:
        return (
          <UpgradeResultView
            icon={ModalContainerIconType.Logo}
            headerText="This team is already on the Team plan"
            bodyContent={
              <p>
                Visit the{' '}
                <ModalContainerButton
                  content={<>billing portal</>}
                  buttonType={ModalContainerButtonType.Button}
                  treatment={ModalContainerButtonTreatment.Inline}
                  accent={ModalContainerButtonAccent.Transparent}
                  onClickFunction={() => {
                    setRedirectingToPortal(true);
                    openStripeBillingPortalMutation();
                  }}
                />{' '}
                to manage your subscription.
              </p>
            }
          />
        );
      // If on enterprise tier, show link to contact support.
      case AllowedCustomerTypes.ENTERPRISE:
        return (
          <UpgradeResultView
            icon={ModalContainerIconType.Logo}
            headerText="This team is on an enterprise plan"
            bodyContent={
              <p>
                Your team is on an enterprise plan. Contact{' '}
                <ModalContainerButton
                  content={<>support</>}
                  treatment={ModalContainerButtonTreatment.Inline}
                  accent={ModalContainerButtonAccent.Transparent}
                  href="mailto:support@warp.dev"
                />{' '}
                for more details.
              </p>
            }
          />
        );
      // Else show error message.
      default:
        return <UpgradeErrorView />;
    }
  }

  // Non-admin cases handled below:
  switch (teamData.customerType) {
    // If on free / free-preview / legacy tier, show message to contact admin to upgrade.
    case AllowedCustomerTypes.FREE:
    case AllowedCustomerTypes.LEGACY:
      if (FEATURE_FLAGS.REACT_APP_ENABLE_PRO_PLAN) {
        return (
          <ProAndTeamPlansView
            isAdmin={false}
            overlay={
              requestedUpgrade && (
                <RequestUpgradeOverlayModal
                  adminEmail={teamData.adminEmail}
                  onInputChange={setRequestUpgradeReason}
                  sendUpgradeRequestButtonClicked={() => {
                    sendUpgradeRequest(
                      teamData.uid,
                      requestedInterval,
                      requestedPlan,
                      requestUpgradeReason
                    );
                  }}
                />
              )
            }
            interval={selectedSubscriptionInterval}
            numMembers={teamData.numMembers}
            monthlyButtonFunction={() =>
              handleSubscriptionIntervalToggle(
                StripeSubscriptionInterval.MONTHLY
              )
            }
            yearlyButtonFunction={() =>
              handleSubscriptionIntervalToggle(
                StripeSubscriptionInterval.YEARLY
              )
            }
            proPlanButtonFunction={() =>
              requestUpgradeFromAdmin(
                selectedSubscriptionInterval,
                StripeSubscriptionPlan.PRO
              )
            }
            teamPlanButtonFunction={() =>
              requestUpgradeFromAdmin(
                selectedSubscriptionInterval,
                StripeSubscriptionPlan.TEAM
              )
            }
          />
        );
      }
      return (
        <OnlyTeamPlansView
          isAdmin={false}
          overlay={
            requestedUpgrade && (
              <RequestUpgradeOverlayModal
                adminEmail={teamData.adminEmail}
                onInputChange={setRequestUpgradeReason}
                sendUpgradeRequestButtonClicked={() => {
                  sendUpgradeRequest(
                    teamData.uid,
                    requestedInterval,
                    requestedPlan,
                    requestUpgradeReason
                  );
                }}
              />
            )
          }
          interval={selectedSubscriptionInterval}
          numMembers={teamData.numMembers}
          monthlyButtonFunction={() =>
            handleSubscriptionIntervalToggle(StripeSubscriptionInterval.MONTHLY)
          }
          yearlyButtonFunction={() =>
            handleSubscriptionIntervalToggle(StripeSubscriptionInterval.YEARLY)
          }
          continueButtonFunction={() =>
            requestUpgradeFromAdmin(
              selectedSubscriptionInterval,
              StripeSubscriptionPlan.TEAM
            )
          }
        />
      );
    // If on prosumer plan, only show the team plans for contacting admin to upgrade.
    case AllowedCustomerTypes.PROSUMER:
      return (
        <OnlyTeamPlansView
          isAdmin={false}
          overlay={
            requestedUpgrade && (
              <RequestUpgradeOverlayModal
                adminEmail={teamData.adminEmail}
                onInputChange={setRequestUpgradeReason}
                sendUpgradeRequestButtonClicked={() => {
                  sendUpgradeRequest(
                    teamData.uid,
                    requestedInterval,
                    requestedPlan,
                    requestUpgradeReason
                  );
                }}
              />
            )
          }
          interval={selectedSubscriptionInterval}
          numMembers={teamData.numMembers}
          monthlyButtonFunction={() =>
            handleSubscriptionIntervalToggle(StripeSubscriptionInterval.MONTHLY)
          }
          yearlyButtonFunction={() =>
            handleSubscriptionIntervalToggle(StripeSubscriptionInterval.YEARLY)
          }
          continueButtonFunction={() =>
            requestUpgradeFromAdmin(
              selectedSubscriptionInterval,
              StripeSubscriptionPlan.TEAM
            )
          }
        />
      );
    // If on self-serve / enterprise tier, show message to contact admin for details about current tier.
    case AllowedCustomerTypes.SELF_SERVE:
    case AllowedCustomerTypes.ENTERPRISE:
      return (
        <UpgradeResultView
          icon={ModalContainerIconType.Logo}
          headerText="This team is already on a paid plan"
          bodyContent={<p>Contact the admin for more details.</p>}
        />
      );
    // Else show error message.
    default:
      return <UpgradeErrorView />;
  }
};

export default UpgradeView;
